import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";

import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js";

import SectionProduct from "./SectionProduct.js";
import { Link as RouterLink } from "react-router-dom";
import { isWebpSupported } from "react-image-webp/dist/utils";
import Iot20kFooter from "../Iot20kFooter";
import SectionGettingStarted from "./SectionGettingStarted";

const useStyles = makeStyles(landingPageStyle);

const LandingPage = props => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();

  const showParallaxArea = true;

  return (
    <div>
      {props.header}
      {showParallaxArea && (
        <Parallax
          image={
            isWebpSupported()
              ? require("assets/img/earth-dark.webp")
              : require("assets/img/earth-dark.png")
          }
        >
          <div
            className={classes.container}
            style={{ paddingTop: "60px", color: "#000" }}
          >
            <GridContainer>
              <GridItem xs={12} sm={8} md={6}>
                <h1 className={classes.title} style={{ color: "white" }}>
                  Your IoT fleet at 20,000 feet
                </h1>
                <Button
                  component={RouterLink}
                  to="/dashboard"
                  color="primary"
                  size="lg"
                  href="/dashboard"
                >
                  <i className="fas fa-play" />
                  {props.loginState.status === "loggedIn"
                    ? "Go to your Dashboard"
                    : "View Demo Dashboard"}
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
      )}
      <div className={classes.main}>
        <div className={classes.container}>
          <SectionProduct />
          <SectionGettingStarted />
          <Iot20kFooter classes={classes} />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
