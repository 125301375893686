import React from "react";
import ReactAnime from "../../views/DashboardPage/ReactAnime";
import { makeStyles } from "@material-ui/core/styles";
import { Rotate90DegreesCcw } from "@material-ui/icons";
import { Typography } from "@material-ui/core";

const { Anime } = ReactAnime;

const useStyles = makeStyles({
  rotateParent: {
    height: "100%",
    width: "100%",
    position: "fixed",
    zIndex: "3",
    backgroundColor: "#12203e",
    overflow: "hidden"
  },
  rotateElement: {
    margin: "0",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center"
  }
});

const RotateDevice = props => {
  const classes = useStyles();

  return (
    <Anime
      onFinished={[
        {
          targets: "#rotate-screen-container",
          opacity: [1, 0],
          easing: "easeInOutQuad",
          duration: 300,
          delay: 0
        }
      ]}
      initial={[
        {
          targets: "#rotate-screen-container",
          opacity: [0, 1],
          easing: "easeInOutQuad",
          duration: 800,
          delay: 0
        }
      ]}
    >
      <div id="rotate-screen-container" className={classes.rotateParent}>
        <div className={classes.rotateElement}>
          <Typography>
            <Rotate90DegreesCcw fontSize="large" />
          </Typography>
          {props.children}
        </div>
      </div>
    </Anime>
  );
};

export default RotateDevice;
