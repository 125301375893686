import React, { useEffect } from "react";

import ReactAnime from "./ReactAnime";
import DotHeatmap from "./DotHeatmap/DotHeatmap";
import { Typography } from "@material-ui/core";

const { Anime } = ReactAnime;

// Memoize the component to prevent constant re-render when the parent component re-renders. It does that each time
// that new live data comes in, even when the data update does not affect the component at all.
const Scene5 = React.memo(props => {
  return (
    props.data && (
      <Anime
        onFinished={() => props.onSceneFinished()}
        initial={[
          {
            targets: "#label",
            opacity: 1,
            translateY: 0,
            easing: "easeInOutQuad",
            duration: 2000
          },
          {
            targets: "circle",
            r: [0, 0.3],
            easing: "easeOutBack",
            duration: 1000,
            delay: el => {
              return el.cx.baseVal.value * 9;
            }
          },
          {
            targets: "#label",
            opacity: [1, 0],
            easing: "easeOutBack",
            duration: 1000,
            delay: 3500
          },
          {
            targets: "circle",
            opacity: [1, 0],
            easing: "easeOutBack",
            duration: 1000,
            timelineOffset: "-=1000",
            delay: el => {
              return el.cx.baseVal.value * 9;
            }
          }
        ]}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100vw",
            background: "#14213d",
            flexDirection: "column"
          }}
        >
          <Typography
            variant="h5"
            id="label"
            style={{
              paddingBottom: "5vh",
              opacity: 0,
              transform: "translateY(30px)"
            }}
          >
            Geographical density of devices
          </Typography>
          <DotHeatmap data={props.data} heatMapName="europe1" />
        </div>
      </Anime>
    )
  );
});

export default Scene5;
