import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Warning } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";

const DashboardDrawerContent = props => {
  return (
    <List>
      <ListItem>
        <FormControlLabel
          control={
            <Switch
              checked={props.autoSwitchEnabled}
              onChange={event =>
                props.setAutoSwitchEnabled(event.target.checked)
              }
              name="autoSwitchEnabled"
            />
          }
          label="Auto switch (shift+a)"
        />
      </ListItem>

      <ListItem>
        <FormControlLabel
          disabled={props.user === null}
          control={
            <Switch
              checked={props.mode === "dummy"}
              onChange={event =>
                event.target.checked
                  ? props.setMode("dummy")
                  : props.setMode("backend")
              }
              name="mode"
            />
          }
          label="Dummy mode"
        />

        {props.user === null && (
          <FormHelperText>
            <Warning />
            <Typography variant="body1">
              Cannot disable dummy mode, not logged in.
            </Typography>
          </FormHelperText>
        )}
      </ListItem>

      <ListItem>
        <FormControlLabel
          control={
            <Switch
              checked={props.newsItemsEnabled}
              onChange={event =>
                props.setNewsItemsEnabled(event.target.checked)
              }
              name="newsItemsEnabled"
            />
          }
          label="New items"
        />
      </ListItem>

      {/*
            {props.timelineSteps.map((step, index) =>
                <ListItem
                    button
                    key={index}
                    onClick={() => props.setTimelineCurrentStep(index)}>
                    <ListItemText primary={index}/>
                </ListItem>)}
*/}
    </List>
  );
};

export default DashboardDrawerContent;
