import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import CircleNavBar from "../../components/CircleNavBar/CircleNavBar";

const SceneSwitcher = props => {
  return (
    <div
      id={props.id}
      className={props.className}
      style={{
        width: "100%",
        textAlign: "center",
        position: "fixed",
        bottom: "20px",
        zIndex: "1000",
        ...props.style
      }}
    >
      <IconButton color="primary" onClick={() => props.stepScene(-1)}>
        <ChevronLeft />
      </IconButton>
      <CircleNavBar
        currentSceneIndex={props.currentSceneIndex}
        scenes={props.scenes}
      />
      <IconButton color="primary" onClick={() => props.stepScene(1)}>
        <ChevronRight />
      </IconButton>
    </div>
  );
};

export default SceneSwitcher;
