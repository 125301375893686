import React, { useEffect, useState } from "react";

import httpMarkdownFile from "./HttpMarkdown.md";
import ReactMarkdown from "react-markdown";

const httpImages = {};

const HttpDocumentation = props => {
  const [httpText, setHttpText] = useState(null);

  useEffect(() => {
    fetch(httpMarkdownFile)
      .then(response => response.text())
      .then(text => {
        setHttpText(text);
      });
  }, []);

  return (
    <div>
      {httpText && (
        <ReactMarkdown
          source={httpText}
          transformImageUri={image => httpImages[image]}
        />
      )}
    </div>
  );
};

export default HttpDocumentation;
