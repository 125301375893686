import React, { useCallback } from "react";
import "odometer/themes/odometer-theme-default.css";
import { Typography } from "@material-ui/core";
import BigSingleNumber from "./BigSingleNumber";
import { makeStyles } from "@material-ui/core/styles";

import ReactAnime from "./ReactAnime";

const { Anime } = ReactAnime;

const useStyles = makeStyles({
  bigSingleNumber: {
    color: "#fff"
  }
});

let getValueAndLabel = (singleNumberType, data) => {
  switch (singleNumberType) {
    case "numberOfMessagesEver":
      return {
        value: data.numberOfMessagesEver,
        label: "Number of messages ever"
      };
    case "numberOfDevices":
      return {
        value: data.numberOfDevices,
        label: "Number of devices"
      };
  }
  return {
    value: 0,
    label: "Unknown"
  };
};

// Memoize the component to prevent constant re-render when the parent component re-renders. It does that each time
// that new live data comes in, even when the data update does not affect the component at all.
const Scene6 = React.memo(props => {
  const classes = useStyles();

  const onSceneFinishedCallback = useCallback(props.onSceneFinished, []);

  if (!props.data) {
    return <div />;
  }

  const { label, value } = getValueAndLabel(props.singleNumberType, props.data);

  return (
    <Anime
      // Every scene *must* call onSceneFinished once it is done
      onFinished={onSceneFinishedCallback}
      initial={[
        {
          targets: "#label",
          opacity: 1,
          translateY: 0,
          easing: "easeInOutQuad",
          duration: 2000
        },
        {
          targets: "#bigSingleNumberContainer",
          opacity: 1,
          easing: "easeInOutQuad",
          duration: 1000,
          delay: 1000
        },
        {
          targets: ["#bigSingleNumberContainer", "#label"],
          opacity: 0,
          easing: "easeInOutQuad",
          duration: 1000,
          delay: 6000
        }
      ]}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          background: "#14213d",
          height: "100vh",
          width: "100vw"
        }}
      >
        <Typography
          id="label"
          variant="h5"
          style={{
            opacity: 0,
            transform: "translateY(30px)"
          }}
        >
          {label}
        </Typography>
        <div
          id="bigSingleNumberContainer"
          style={{
            opacity: 0
          }}
        >
          <BigSingleNumber
            id="number"
            className={classes.bigSingleNumber}
            value={value}
            maxUpdateInterval={5000}
          />
        </div>
      </div>
    </Anime>
  );
});

export default Scene6;
