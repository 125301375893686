import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";
import { Drafts, Inbox } from "@material-ui/icons";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import List from "@material-ui/core/List";

const useStyles = makeStyles(productStyle);

export default function SectionGettingStarted() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h2 className={classes.title}>Get started for free</h2>
          <List aria-label="main mailbox folders">
            <ListItem>
              <ListItemIcon>
                <Inbox />
              </ListItemIcon>
              <ListItemText
                primary="One million communication events per month"
                style={{ color: "black" }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Drafts />
              </ListItemIcon>
              <ListItemText
                primary="No credit card required"
                style={{ color: "black" }}
              />
            </ListItem>
          </List>
        </GridItem>
      </GridContainer>
    </div>
  );
}
