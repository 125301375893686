import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import standardPageStyle from "../../assets/jss/standardPageStyle";
import { Paper } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import awsLogo from "assets/img/aws/logo.svg";
import azureLogo from "assets/img/azure/logo.svg";
import slackLogo from "assets/img/slack/logo.svg";
import httpLogo from "assets/img/http-logo.svg";

const useStyles = makeStyles(standardPageStyle);

const DocBox = props => {
  return (
    <RouterLink to={props.to}>
      <Paper
        square
        elevation={3}
        style={{
          width: 180,
          height: 180,
          maxHeight: "100%",
          maxWidth: "100%",
          margin: "5px",
          marginTop: "15px"
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <div>
            <img
              src={props.logo}
              alt={props.text}
              style={{ height: 80, paddingBottom: "15px" }}
            />
          </div>
          <div>
            <p>{props.text}</p>
          </div>
        </Box>
      </Paper>
    </RouterLink>
  );
};

const DocumentationPage = () => {
  const classes = useStyles();

  const topics = [
    {
      text: "HTTP API",
      path: "http",
      logo: httpLogo
    },
    {
      text: "AWS Integration",
      path: "aws",
      logo: awsLogo
    },
    {
      text: "Azure Integration",
      path: "azure",
      logo: azureLogo
    },
    {
      text: "Slack Integration",
      path: "slack",
      logo: slackLogo
    }
  ];

  return (
    <div className={classes.section}>
      <a id="docs" />
      <h3>Topics</h3>

      <GridContainer>
        {topics.map(topic => (
          <GridItem key={topic} xs={6} sm={4} md={3}>
            <DocBox
              logo={topic.logo}
              text={topic.text}
              to={`/documentation/${topic.path}`}
            />
          </GridItem>
        ))}
      </GridContainer>
    </div>
  );
};

export default DocumentationPage;
