import React, { useEffect, useState } from "react";
import * as portals from "react-reverse-portal";
import ReactAnime from "./ReactAnime";
import Typography from "@material-ui/core/Typography";
//import "highlight.js/styles/a11y-light.css";
import { Paper } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import ExampleRequest from "../AdministrationPage/ExampleRequest";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import Button from "@material-ui/core/Button";

const { Anime } = ReactAnime;

const useStyles = makeStyles({
  "@global pre > code": {
    fontSize: "x-small"
  },
  instructionBox: {
    height: "fit-content",
    minWidth: "30vw"
  },
  hidden: {
    opacity: 0
  }
});

const SceneWaitingForFirstData = props => {
  const [tabValue, setTabValue] = useState("0");

  const [dots, setDots] = useState(0);
  const [firstPosition, setFirstPosition] = useState(null);

  useEffect(() => {
    const timer = setInterval(
      () => setDots(current => (current + 1) % 3),
      1000
    );
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    setFirstPosition(current => {
      if (current) {
        return current;
      }

      if (props.data && props.data.positions.length > 0) {
        return props.data.positions[0];
      }

      return current;
    });
  }, [props.data]);

  const classes = useStyles();

  const panAndPulse = firstPosition ? { position: firstPosition } : null;

  // When the first position has been received, end the scene
  useEffect(() => {
    let timer;
    if (firstPosition) {
      timer = setTimeout(() => props.onSceneFinished(), 4000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [firstPosition]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        flexDirection: "column"
      }}
    >
      <div style={{ color: "white" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {/*          <div>
            <Typography
              variant="h5"
              style={{
                marginBottom: "3vh",
                animation: "all 1s"
              }}
            >
              {firstPosition
                ? "First data received!"
                : "Waiting for you to send your first request" +
                  ".".repeat(dots + 1)}
            </Typography>
            <Paper className={classes.instructionBox}>
              <TabContext value={tabValue}>
                <TabList onChange={(event, newValue) => setTabValue(newValue)}>
                  <Tab label="Bash" value="0" style={{ fontSize: "inherit" }} />
                  <Tab
                    label="Powershell"
                    value="1"
                    style={{ fontSize: "inherit" }}
                  />
                  <Tab
                    label="AWS & Azure"
                    value="2"
                    style={{ fontSize: "inherit" }}
                  />
                </TabList>
                <TabPanel value="0">
                  <ExampleRequest language="bash" tenantDoc={props.tenantDoc} />
                </TabPanel>
                <TabPanel value="1">
                  <ExampleRequest
                    language="powershell"
                    tenantDoc={props.tenantDoc}
                  />
                </TabPanel>
                <TabPanel value="2">
                  See{" "}
                  <Link
                    to="/documentation"
                    component={RouterLink}
                    target="_blank"
                  >
                    Documentation
                  </Link>
                  <Button
                    onClick={() => setFirstPosition({ lat: 48.1, lon: 11.58 })}
                  >
                    Click me
                  </Button>
                </TabPanel>
              </TabContext>
            </Paper>
          </div>
          <div style={{ marginLeft: "10vw" }}>
          */}
          <div>
            <Anime
              initial={[
                {
                  targets: "#earth",
                  opacity: 1,
                  translateY: 0,
                  easing: "easeInOutQuad",
                  duration: 2000
                }
              ]}
            >
              <div
                id="earth"
                style={{ opacity: 0, transform: "translateY(30px)" }}
              >
                <portals.OutPortal
                  node={props.earthContainer}
                  panAndPulse={panAndPulse}
                />
              </div>
            </Anime>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SceneWaitingForFirstData;
