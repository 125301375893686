import React, { useEffect, useState } from "react";
import * as portals from "react-reverse-portal";

import ReactAnime from "./ReactAnime";
import CurrentValues from "./CurrentValues";

const { Anime } = ReactAnime;

const Scene2 = props => {
  const [currentValuesRetracted, setCurrentValuesRetracted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      // After all animations have finished
      setCurrentValuesRetracted(true);
    }, 6000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%"
        }}
      >
        <div>
          <Anime
            // Every scene *must* call onSceneFinished once it is done
            onFinished={() => props.onSceneFinished()}
            initial={[
              {
                targets: "#earth",
                opacity: 1,
                translateY: 0,
                easing: "easeInOutQuad",
                duration: 2000
              },
              {
                targets: "#earth",
                opacity: 0,
                easing: "easeInOutQuad",
                duration: 2000,
                delay: 5000
              }
            ]}
          >
            <div
              id="earth"
              style={{ opacity: 0, transform: "translateY(30px)" }}
            >
              <portals.OutPortal
                node={props.earthContainer}
                data={props.data}
              />
            </div>
          </Anime>
        </div>
      </div>
      <div style={{ position: "absolute", right: "10vw", top: "10px" }}>
        <CurrentValues data={props.data} retract={currentValuesRetracted} />
      </div>
    </>
  );
};

export default Scene2;
