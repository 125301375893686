import React, { useEffect, useState } from "react";
import LoginPage from "./LoginPage/LoginPage";
import ErrorPage from "./ErrorPage/ErrorPage";
import DocumentationPage from "./DocumentationPage/DocumentationPage";
import LandingPage from "./LandingPage/LandingPage";
import { Route } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import firebase from "firebase";
import Header from "../components/Header/Header";
import Iot20kFooter from "./Iot20kFooter";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AdministrationPage from "./AdministrationPage/AdministrationPage";
import ScreenAutoLogin from "./ScreenAutoLogin";
import SignupPage from "./SignupPage/SignupPage";
import appStyle from "../assets/jss/appStyle";
import PricingPage from "./PricingPage/PricingPage";
import Dashboard from "./DashboardPage/Dashboard";
import Pairing from "./DashboardPage/Pairing";
import ScreenAdministration from "./AdministrationPage/ScreenAdministration";
import DocumentationDetailPage from "./DocumentationPage/DocumentationDetailPage";
import SlackAdministration from "./AdministrationPage/SlackAdministration";
import { sleep } from "../utils";

const useStyles = makeStyles(appStyle);

const firebaseConfig = {
  apiKey: "AIzaSyA7EIyyrdmB8HJwjQZLjSLi7Z5GWpP0IcI",
  authDomain: "iotglobe.firebaseapp.com",
  databaseURL: "https://iotglobe.firebaseio.com",
  projectId: "iotglobe",
  storageBucket: "iotglobe.appspot.com",
  appId: "1:352488322234:web:67501da8a3a08c040a7e83",
  measurementId: "G-NVJ06HWG0J"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const firestore = firebaseApp.firestore();
const functions = firebaseApp.functions("europe-west1");
const auth = firebaseApp.auth();

const firebaseUiConfig = {
  signInFlow: "redirect",
  signInSuccessUrl: "/loggedIn",
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID
    },
    {
      provider: "microsoft.com"
    },
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
    }
  ]
};

const App = () => {
  const classes = useStyles();

  const [loginState, setLoginState] = useState({
    status: "unknown",
    user: null
  });
  const [screenKey, setScreenKey] = useState(
    JSON.parse(localStorage.getItem("screenKey")) || null
  );
  const [tenantDoc, setTenantDoc] = useState(null);
  const [idToken, setIdToken] = useState(null);

  const getScreenKeyCustomAuthToken = functions.httpsCallable(
    "getScreenKeyCustomAuthToken"
  );

  const loginWithScreenKey = async sk => {
    const {
      data: { token }
    } = await getScreenKeyCustomAuthToken({ screenKey: sk });
    await auth.signInWithCustomToken(token);
    console.log(`Login with screen key ${sk} finished.`);
  };

  const updateIdToken = async forceRefresh => {
    setIdToken(await auth.currentUser.getIdToken(forceRefresh));
    return await auth.currentUser.getIdTokenResult();
  };

  const history = useHistory();
  const location = useLocation();

  const getTypeAndTenant = async () => {
    let type = null;
    let tenant = null;

    let numberOfTries = 0;
    while (numberOfTries < 5) {
      const nextIdTokenResult = await updateIdToken(true);
      type = nextIdTokenResult.claims.typ;
      tenant = nextIdTokenResult.claims.tnt;

      if (type) {
        return { type, tenant };
      }

      console.error(
        `Even after refreshing the id token, the 'typ' (value: ${type}) custom claim is not present. Try: ${numberOfTries}`
      );
      numberOfTries++;
      await sleep(1000);
    }

    return { type, tenant };
  };

  const updateTenantDoc = async tenantId => {
    const doc = await firestore
      .collection("tenants")
      .doc(tenantId)
      .get();
    setTenantDoc({
      ...doc.data(),
      id: doc.id
    });
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async u => {
      try {
        if (u) {
          console.log("User " + u.uid + " is signed in.");

          const idTokenResult = await updateIdToken(false);
          let tenantId = idTokenResult.claims.tnt;
          let type = idTokenResult.claims.typ;

          if (type === undefined) {
            console.log(
              "Custom claim 'typ' is not present (maybe the user was just created), refreshing the token"
            );
            const typeAndTenant = await getTypeAndTenant();
            type = typeAndTenant.type;
            tenantId = typeAndTenant.tenant;
          }

          console.log(`Tenant: ${tenantId}, user type: ${type}`);

          if (type === "admin") {
            if (tenantId === undefined) {
              if (location.pathname === "/loggedIn") {
                console.log(
                  "The user is not associated with a tenant, forwarding to signup page"
                );
                history.push("/signup");
              } else if (location.pathname === "/signup") {
                console.log(
                  "The user is not associated with a tenant and is already on signup page"
                );
              } else {
                console.log(
                  "The user is not associated with a tenant, but is neither on /loggedIn nor /signup page, logging out"
                );
                signOut();
              }
            } else {
              // Populate tenantDoc state
              await updateTenantDoc(tenantId);
            }
          }

          setLoginState({
            status: "loggedIn",
            user: { ...u, type, tenant: tenantId }
          });
        } else {
          console.log("No user is signed in");
          setLoginState({ status: "loggedOut", user: null });
        }
      } catch (e) {
        console.error("Error handing login: " + e);
      }
    });
  }, []);

  // When a pairing was performed successfully, login using the screen key
  const onPairingComplete = async () => {
    console.log(`Pairing complete, login in with screen key ${screenKey}`);
    await loginWithScreenKey(screenKey);
  };

  const onScreenKeyCreated = async createdScreenKey => {
    setScreenKey(createdScreenKey);
  };

  const signOut = () => {
    setLoginState({ status: "loggedOut", user: null });
    firebase.auth().signOut();
  };

  const headerCommonProps = {
    user: loginState.user,
    absolute: false,
    color: "primary",
    brand: "IoT 20k",
    signOut
  };

  const signupFinished = async ({ tenantName, timezone }) => {
    console.log(`Tenant Doc Updated: Name: ${tenantName}`);
    const idTokenResult = await updateIdToken(false);
    const tenantId = idTokenResult.claims.tnt;
    await updateTenantDoc(tenantId);
    setTenantDoc(current => {
      const updatedTenantDoc = {
        ...current,
        tenantName,
        timezone,
        waitingForFirstData: true
      };
      console.log(`Updated tenant doc: ${JSON.stringify(updatedTenantDoc)}`);
      return updatedTenantDoc;
    });
  };

  // Persist the screen key when it was generated
  useEffect(() => {
    if (
      screenKey &&
      screenKey !== JSON.parse(localStorage.getItem("screenKey"))
    ) {
      localStorage.setItem("screenKey", JSON.stringify(screenKey));
    }
  }, [screenKey]);

  const standardPages = [
    "/error-page",
    "/documentation",
    "/pricing",
    "/administration",
    "/login",
    "/signup",
    "/loggedIn"
  ];

  return (
    <>
      <ScreenAutoLogin
        screenKey={screenKey}
        loginState={loginState}
        loginWithScreenKey={loginWithScreenKey}
      />

      <Route path={standardPages}>
        <Header {...headerCommonProps} />
        <div className={classes.main}>
          <div className={classes.container} style={{ color: "#000" }}>
            <Route path="/error-page" component={ErrorPage} />
            <Route exact path="/documentation" component={DocumentationPage} />
            <Route
              path="/documentation/:section"
              render={props => (
                <DocumentationDetailPage section={props.match.params.section} />
              )}
            />

            <Route path="/pricing" render={() => <PricingPage />} />

            <Route
              exact
              path="/administration"
              render={() => (
                <AdministrationPage
                  user={loginState.user}
                  tenantDoc={tenantDoc}
                  functions={functions}
                  firestore={firestore}
                />
              )}
            />

            <Route
              path="/administration/screens"
              render={() => (
                <ScreenAdministration
                  user={loginState.user}
                  tenantDoc={tenantDoc}
                  functions={functions}
                  firestore={firestore}
                />
              )}
            />

            <Route
              path="/administration/slack"
              render={() => (
                <SlackAdministration
                  user={loginState.user}
                  tenantDoc={tenantDoc}
                  functions={functions}
                  firestore={firestore}
                />
              )}
            />

            <Route
              path="/login"
              render={() => <LoginPage firebaseUiConfig={firebaseUiConfig} />}
            />

            <Route
              exact
              path="/loggedIn"
              render={() => (
                <LoginPage
                  loginSuccessfullyCompleted
                  firebaseUiConfig={firebaseUiConfig}
                  loginState={loginState}
                />
              )}
            />

            <Route
              exact
              path="/signup"
              render={() => (
                <SignupPage
                  loginState={loginState}
                  functions={functions}
                  auth={auth}
                  signupFinished={signupFinished}
                />
              )}
            />

            <Iot20kFooter classes={classes} />
          </div>
        </div>
      </Route>

      <Route
        path="/dashboard"
        render={() => (
          <Dashboard
            mode="firestore"
            user={loginState.user}
            auth={auth}
            idToken={idToken}
            firebaseApp={firebaseApp}
            firestore={firestore}
            functions={functions}
            tenantDoc={tenantDoc}
          />
        )}
      />

      <Route
        path="/pair"
        render={() => (
          <Pairing
            firestore={firestore}
            functions={functions}
            auth={auth}
            screenKey={screenKey}
            loginState={loginState}
            onPairingComplete={onPairingComplete}
            onScreenKeyCreated={onScreenKeyCreated}
          />
        )}
      />

      <Route
        exact
        path="/"
        render={() => (
          <LandingPage
            loginState={loginState}
            header={
              <Header
                {...headerCommonProps}
                color="transparent"
                changeColorOnScroll={{
                  height: 100,
                  color: "primary"
                }}
                fixed
              />
            }
          />
        )}
      />
    </>
  );
};

export default App;
