import React, { useEffect, useState } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { useHistory } from "react-router";

const Pairing = props => {
  const [pairingNecessary, setPairingNecessary] = useState(null);
  const [pairingKey, setPairingKey] = useState(null);
  const [statusMessage, setStatusMessage] = useState("Creating pairing key...");

  const createPairingKey = props.functions.httpsCallable("createPairingKey");
  const createScreenKey = props.functions.httpsCallable("createScreenKey");

  const history = useHistory();

  // If no user is signed in, we are on the pairing page and no screen key is already present: Create a screen key

  // TODO: Handle exceptions
  useEffect(() => {
    if (props.screenKey === null && props.loginState.status === "loggedOut") {
      (async function() {
        console.log(
          "There is no screen key and no user is signed in, so we have to generate a new screen key"
        );
        const creationResult = await createScreenKey();
        const createdScreenKey = creationResult.data.screenKey;
        props.onScreenKeyCreated(createdScreenKey);
      })();
    } else if (
      props.screenKey !== null &&
      props.loginState.status === "loggedOut"
    ) {
      (async function() {
        console.log(
          "There is a screen key and no user is signed in, so we have to perform the pairing"
        );
        await performPairing();
        setStatusMessage("Pairing successful, logging in...");
        await props.onPairingComplete();
      })();
    } else if (
      props.loginState.status === "loggedIn" &&
      props.loginState.user.type === "screen"
    ) {
      // Screen key is already paired, simply redirect to the dashboard
      setStatusMessage("Login successful, forwarding to dashboard...");
      setTimeout(() => history.push("/dashboard"), 1000);
    }
  }, [props.screenKey, props.loginState]);

  const performPairing = async () => {
    const {
      data: { pairingKey }
    } = await createPairingKey({ screenKey: props.screenKey });

    setStatusMessage(
      "Waiting for admin user to enter pairing key on https://iot20k.com/administration"
    );
    console.log(`Received pairing key: ${pairingKey}`);
    setPairingKey(pairingKey);
    setPairingNecessary(true);

    return new Promise((resolve, reject) => {
      let initialQueryResult = true;
      const unsubscribe = props.firestore
        .collection("pairingKeys")
        .doc(`${props.screenKey}-${pairingKey}`)
        .onSnapshot(documentSnapshot => {
          if (initialQueryResult) {
            // We ignore the first result, since is simply reads the document without waiting
            initialQueryResult = false;
            return;
          }

          console.log("Received update for pairing key");
          console.dir(documentSnapshot);
          // We are only interested in the first update
          unsubscribe();
          if (documentSnapshot.data().pairingComplete) {
            resolve();
          } else {
            reject(
              "Got an update for the pairing key document, but pairingComplete was still false."
            );
          }
        });
    });
  };

  useEffect(() => {
    if (!props.screenKey) {
      return;
    }

    if (!props.user) {
      return;
    }

    if (props.user.type === "admin") {
      // TODO: Decide what should happen in this case? Display message at least.
      console.log("Already signed in as an admin user. Not pairing.");
      return;
    }

    if (props.user.type !== "screen") {
      console.log("Cannot handle user type: " + props.user.type);
      return;
    }

    // Recommended by React, because hook function must not be asynchronous
    (async function() {
      try {
      } catch (e) {
        console.error("Error initiating or performing pairing: " + e);
      }
    })();
  }, [props.user, props.screenKey]);

  return (
    <Grid container>
      <Grid item xs={4} />
      <Grid item xs={4}>
        <h2>{statusMessage}</h2>
        {pairingNecessary === null && (
          <div>
            <CircularProgress />
          </div>
        )}
        {pairingNecessary && (
          <div>
            <Alert severity="info">
              <AlertTitle>Pairing necessary</AlertTitle>
              In order to pair, go to https://iot20k.com/administration and add
              the following pairing key
            </Alert>
            <div>
              <h1>{pairingKey}</h1>
            </div>
          </div>
        )}
      </Grid>
      <Grid item xs={4} />
    </Grid>
  );
};

export default Pairing;
