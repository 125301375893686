import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import {
  ExitToApp,
  ExitToAppOutlined,
  LibraryBooksOutlined,
  MonetizationOnOutlined,
  Person,
  PlayCircleOutline,
  Settings,
  SettingsOutlined
} from "@material-ui/icons";
import { Divider, Tooltip, withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(styles);

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}))(Tooltip);

const isAdmin = user => user && user.type === "admin" && user.tenant;

const isScreenUser = user => user && user === "screen";

const isValidUser = user => isScreenUser(user) || isAdmin(user);

export default function HeaderLinks(props) {
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  // eslint-disable-next-line no-unused-vars
  const smoothScroll = (e, target) => {
    if (window.location.pathname === "/sections") {
      var isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      );
      if (isMobile) {
        // if we are on mobile device the scroll into view will be managed by the browser
      } else {
        e.preventDefault();
        var targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
      }
    }
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function() {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  const classes = useStyles();
  const linkFollowed = () => {
    if (props.linkFollowed) {
      props.linkFollowed();
    }
  };

  const PricingListItem = props => (
    <ListItem className={classes.listItem}>
      <Link
        className={classes.navLink}
        to="/pricing"
        href="/pricing"
        component={RouterLink}
        onClick={linkFollowed}
      >
        {props.icon && <MonetizationOnOutlined fontSize="small" />}
        Pricing
      </Link>
    </ListItem>
  );

  const DocumentationListItem = props => (
    <ListItem className={classes.listItem}>
      <Link
        className={classes.navLink}
        to="/documentation"
        href="/documentation"
        component={RouterLink}
        onClick={linkFollowed}
      >
        {props.icon && <LibraryBooksOutlined fontSize="small" />}
        Documentation
      </Link>
    </ListItem>
  );

  const LoginListItem = props => (
    <ListItem className={classes.listItem}>
      <Link
        className={classes.navLink}
        to="/login"
        href="/login"
        component={RouterLink}
        onClick={linkFollowed}
      >
        {props.icon && <ExitToAppOutlined fontSize="small" />}
        Login/Signup
      </Link>
    </ListItem>
  );

  const DashboardListItem = props => (
    <ListItem className={classes.listItem}>
      <Link
        className={classes.navLink}
        to="/dashboard"
        href="/dashboard"
        component={RouterLink}
        onClick={linkFollowed}
      >
        {props.icon && <PlayCircleOutline fontSize="small" />}
        Dashboard
      </Link>
    </ListItem>
  );

  const LoggedInUser = () => (
    <Grid
      container
      direction="row"
      alignItems="center"
      className={classes.navLink}
    >
      <Person fontSize="small" />
      <b>
        {isScreenUser(props.user) && "Logged in as a screen"}
        {isAdmin(props.user) && props.user.displayName}
      </b>
    </Grid>
  );
  const LoggedInUserListItem = () => (
    <ListItem className={classes.listItem}>
      <LoggedInUser />
    </ListItem>
  );

  const AdministrationListItem = () => (
    <ListItem className={classes.listItem}>
      <LightTooltip title="Administration">
        <IconButton
          to="/administration"
          href="/administration"
          style={{ color: "inherit" }}
          component={RouterLink}
          onClick={linkFollowed}
        >
          <Settings />
        </IconButton>
      </LightTooltip>
    </ListItem>
  );

  const AdministrationTextListItem = props => (
    <ListItem className={classes.listItem}>
      <LightTooltip title="Administration">
        <Link
          className={classes.navLink}
          to="/administration"
          href="/administration"
          component={RouterLink}
          onClick={linkFollowed}
        >
          {props.icon && <SettingsOutlined fontSize="small" />}
          Administration
        </Link>
      </LightTooltip>
    </ListItem>
  );

  const LogoutTextListItem = ownProps => (
    <ListItem className={classes.listItem}>
      <Link
        className={classes.navLink}
        onClick={() => {
          props.signOut();
          linkFollowed();
        }}
        to="/"
        href="/"
        component={RouterLink}
      >
        {ownProps.icon && <ExitToAppOutlined fontSize="small" />}
        Logout
      </Link>
    </ListItem>
  );

  return props.variant === "smallScreen" ? (
    <>
      {isValidUser(props.user) && <LoggedInUser />}
      <Divider />
      <List className={classes.list + " " + classes.mlAuto}>
        <PricingListItem icon={true} />
        <DocumentationListItem icon={true} />
        <DashboardListItem icon={true} />
        {isValidUser(props.user) ? (
          <>
            <AdministrationTextListItem icon={true} />
            <LogoutTextListItem icon={true} />
          </>
        ) : (
          <LoginListItem icon={true} />
        )}
      </List>
    </>
  ) : (
    <List className={classes.list + " " + classes.mlAuto}>
      <PricingListItem />
      <DocumentationListItem />
      <DashboardListItem />
      {isValidUser(props.user) ? (
        <>
          <LoggedInUserListItem />
          <AdministrationListItem />
          <LightTooltip title="Logout">
            <IconButton style={{ color: "inherit" }} onClick={props.signOut}>
              <ExitToApp />
            </IconButton>
          </LightTooltip>
        </>
      ) : (
        <LoginListItem />
      )}
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};
