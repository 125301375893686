import React, { useEffect, useState } from "react";

import azureMarkdownFile from "./AzureMarkdown.md";
import ReactMarkdownWithHtml from "react-markdown/with-html";

import azureDiagram from "assets/img/azure/diagram.png";
import { markdownRenderers } from "./DocUtils";

const azureImages = {
  diagram: azureDiagram
};

const AzureDocumentation = props => {
  const [azureText, setAzureText] = useState(null);

  useEffect(() => {
    fetch(azureMarkdownFile)
      .then(response => response.text())
      .then(text => {
        setAzureText(text);
      });
  }, []);

  return (
    <div>
      {azureText && (
        <ReactMarkdownWithHtml
          children={azureText}
          transformImageUri={image => azureImages[image]}
          skipHtml={false}
          allowDangerousHtml={true}
          renderers={markdownRenderers}
        />
      )}
    </div>
  );
};

export default AzureDocumentation;
