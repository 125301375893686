import React, { useState } from "react";

import ReactAnime from "./ReactAnime";
import Typography from "@material-ui/core/Typography";
import { uniqueId } from "lodash";
import { makeStyles } from "@material-ui/core/styles";

const { Anime } = ReactAnime;

const useStyles = makeStyles({
  textWrapper: {
    position: "relative",
    display: "inline-block",
    whiteSpace: "pre-wrap"
  },
  letter: {
    display: "inline-block",
    fontSize: "8vh",
    color: "white",
    opacity: 0
  },
  letterAlternateStyle: {
    display: "inline-block",
    fontSize: "4vh",
    color: "#e5e5e5",
    opacity: 0
  },
  "@keyframes scaleInBottomLeft": {
    "0%": {
      transform: "scale(0)",
      transformOrigin: "0% 100%",
      opacity: 1
    },
    "100%": {
      transform: "scale(1)",
      transformOrigin: "0% 100%",
      opacity: 1
    }
  },
  scaleInBottomLeft: {
    animation:
      "$scaleInBottomLeft 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both"
  },
  bullet: {
    opacity: 1.0,
    display: "inline-block",
    backgroundColor: "white",
    width: "3vh",
    height: "3vh"
  },
  hidden: {
    opacity: 0.0
  }
});

const SimpleLine = props => {
  const classes = useStyles();

  const initialDelay = props.delay || 0;
  const characterDelay = props.characterDelay || 15;

  const letterClass = props.alternateStyle
    ? classes.letterAlternateStyle
    : classes.letter;
  const bulletClass = classes.bullet;

  const [rootId, setRootId] = useState(uniqueId("simple-line-"));

  // The first timeline step does nothing but delaying the start
  const animeJsTimeline = [
    {
      duration: initialDelay
    }
  ];

  if (props.bullet) {
    animeJsTimeline.push({
      targets: `#${rootId} .${bulletClass}`,
      scale: [0, 1],
      transformOrigin: ["0% 100%", "0% 100%"],
      duration: 150,
      easing: "linear"
    });
  }

  animeJsTimeline.push({
    targets: `#${rootId} .${letterClass}`,
    opacity: [0, 1],
    easing: "easeOutExpo",
    duration: 600,
    delay: (el, i) => characterDelay * (i + 1)
  });

  return (
    <Anime
      animeConfig={{
        loop: false
      }}
      initial={animeJsTimeline}
    >
      <div id={rootId} className="ml11">
        {props.bullet && (
          <>
            <div className={classes.bullet} style={{}} />
            &nbsp;
          </>
        )}
        <span className={classes.textWrapper}>
          <Typography variant={props.variant} className="letter">
            {props.text.split("").map((char, i) => (
              <span key={i} className={letterClass}>
                {char}
              </span>
            ))}
          </Typography>
        </span>
      </div>
    </Anime>
  );
};

export default SimpleLine;
