import React, { useCallback } from "react";
import "odometer/themes/odometer-theme-default.css";

import ReactAnime from "./ReactAnime";
import CountryMap from "./CountryMap/CountryMap";

const { Anime } = ReactAnime;

/*
TODO:
* Add intro, outro
*/

// Memoize the component to prevent constant re-render when the parent component re-renders. It does that each time
// that new live data comes in, even when the data update does not affect the component at all.
const Scene7 = React.memo(props => {
  const onSceneFinishedCallback = useCallback(props.onSceneFinished, []);

  if (!props.data) {
    return <div />;
  }

  return (
    <Anime
      // Every scene *must* call onSceneFinished once it is done
      //onFinished={onSceneFinishedCallback}
      initial={[]}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          background: "#14213d",
          height: "100vh",
          width: "100vw"
        }}
      >
        <CountryMap countryCode="de" positions={props.data.positions} />
      </div>
    </Anime>
  );
});

export default Scene7;
