import React from "react";

import { ReactComponent as EuropeSvg } from "./europe.svg";
import { ReactComponent as WorldSvg } from "./world.svg";

import ReactAnime from "../ReactAnime";
import { makeStyles } from "@material-ui/core/styles";

const { Anime } = ReactAnime;

const useStyles = gridIdMap => {
  const style = {};

  let minCount = null;
  let maxCount = null;

  Object.keys(gridIdMap).forEach(gridId => {
    if (minCount === null || gridIdMap[gridId] < minCount) {
      minCount = gridIdMap[gridId];
    }

    if (maxCount === null || gridIdMap[gridId] > maxCount) {
      maxCount = gridIdMap[gridId];
    }
  });

  Object.keys(gridIdMap).forEach(gridId => {
    const deviceCount = gridIdMap[gridId];
    if (deviceCount > 0) {
      const cssSelector = `@global .dot-${gridId}`;
      // The lowest count gets saturation 20%, the highest 100%
      const percentage = 20 + ((deviceCount - minCount) / maxCount) * 70;
      style[cssSelector] = { fill: `hsl(0, ${percentage}%, 50%)` };
    }
  });

  return makeStyles(style)();
};

const DotHeatmap = props => {
  const gridIdMap = props.data[props.heatMapName];

  if (!gridIdMap) {
    console.warn("No data found for heatMapName: " + props.heatMapName);
  }

  const classes = useStyles(gridIdMap);

  return (
    <div>
      {props.heatMapName === "europe1" ? (
        <EuropeSvg style={{ fill: "silver" }} height="65vh" />
      ) : (
        <WorldSvg style={{ fill: "white" }} height="65vh" />
      )}
    </div>
  );
};

export default DotHeatmap;
