import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";

import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase";
import { useHistory } from "react-router";
import { CheckCircleOutlined } from "@material-ui/icons";

const useStyles = makeStyles(loginPageStyle);

/*
This page is used for both displaying the login form (via Firebase UI) and as the redirect target from Firebase UI
once login was completed. In the first case, its URL is `/login` in the second its URL is `loggedIn`.
*/
const LoginPage = props => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();

  const history = useHistory();

  // When we return to the login page on the URL `/loggedIn` from Firebase Auth, we check if the tenant is already
  // associated with a tenant. If so, he's good to go.
  if (
    props.loginSuccessfullyCompleted &&
    props.loginState.user &&
    props.loginState.user.tenant
  ) {
    history.push("/dashboard");
  }

  return (
    <div className={classes.container}>
      <GridContainer justify="center" style={{ paddingTop: "10%" }}>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <form className={classes.form}>
              <CardHeader color="primary" signup className={classes.cardHeader}>
                <h4 className={classes.cardTitle}>Login/Signup</h4>
              </CardHeader>
              <CardBody signup>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.textCenter}>
                      {props.loginSuccessfullyCompleted ? (
                        <h4 style={{ paddingBottom: "80px" }}>
                          <CheckCircleOutlined style={{ fontSize: "5em" }} />
                          <br />
                          Login successful
                        </h4>
                      ) : (
                        <StyledFirebaseAuth
                          uiConfig={props.firebaseUiConfig}
                          firebaseAuth={firebase.auth()}
                        />
                      )}
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default LoginPage;
