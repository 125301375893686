import React, { useEffect } from "react";
import { useLocation } from "react-router";

const ScreenAutoLogin = props => {
  const location = useLocation();

  useEffect(() => {
    (async function() {
      try {
        if (
          props.loginState.status === "loggedOut" &&
          props.screenKey !== null
        ) {
          // If no user is signed in, sign in automatically using the screen key if it exists
          const path = location.pathname;
          if (path === "/dashboard") {
            console.log(
              `Using existing screen key ${props.screenKey} to sign in`
            );
            await props.loginWithScreenKey(props.screenKey);
          }
        }
      } catch (e) {
        console.error("Error handing login: " + e);
      }
    })();
  }, [props.loginState, props.screenKey]);

  return <></>;
};
export default ScreenAutoLogin;
