import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Chat from "@material-ui/icons/Chat";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import globe from "assets/img/globe.gif";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";
import { Public, Room, Timeline, TouchApp } from "@material-ui/icons";
import { Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles(productStyle);

export default function SectionProduct() {
  const classes = useStyles();
  return (
    <div className={classes.section} id="featuresSection">
      <a id="features" />
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h2 className={classes.title}>
            Showcase the activity of your IoT devices in real-time on a big
            screen.
          </h2>
          <h5 className={classes.description}>
            Impress customers. Motivate your team.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <InfoArea
              title="3D Globe View"
              description={
                <span>
                  View communication events on a 3D globe. See this and the
                  other available visualizations in action{" "}
                  <RouterLink to="/dashboard">here</RouterLink>.
                  <div>
                    <img src={globe} alt="globe" style={{ padding: "15px" }} />
                  </div>
                </span>
              }
              icon={Public}
              iconColor="primary"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <InfoArea
              title="Statistics"
              description="View all kinds of helpful statistical insights about your fleet."
              icon={Timeline}
              iconColor="primary"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <InfoArea
              title="Easy Integration"
              description={
                <span>
                  It's very easy to integrate IoT 20k thanks to our simple{" "}
                  <RouterLink to="/documentation/http">HTTP API</RouterLink>.
                  There are turnkey integrations for{" "}
                  <RouterLink to="/documentation/aws">AWS IoT</RouterLink> and
                  for{" "}
                  <RouterLink to="/documentation/azure">Azure IoT</RouterLink>.
                </span>
              }
              icon={TouchApp}
              iconColor="primary"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <InfoArea
              title="Messaging"
              description="IoT 20k can post new updates to your Slack or Microsoft Teams channel"
              icon={Chat}
              iconColor="primary"
              vertical
            />
          </GridItem>
          {/*
          <GridItem xs={12} sm={6} md={6}>
            <InfoArea
              title="Track Device Activity"
              description="See how many of your devices are active with configurable metrics"
              icon={NotificationsActive}
              iconColor="primary"
              vertical
            />
          </GridItem>
*/}
          <GridItem xs={12} sm={6} md={6}>
            <InfoArea
              title="Geo IP Resolution"
              description="Instead of sending the geo position of your devices, you can also
              send us their IP addresses. We will determine the geo position based on the IP address.
              We are working on support for geo-coding based on postal addresses as well."
              icon={Room}
              iconColor="primary"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
