/* TODO:
 * * Consolidate data into a single map?
 */

// This can certainly be done a lot smarter...
import CountryDe from "../../../assets/img/countries/de.svg";
import CountryUs from "../../../assets/img/countries/us.svg";
import CountrySe from "../../../assets/img/countries/se.svg";
import CountryAt from "../../../assets/img/countries/at.svg";
import CountryCh from "../../../assets/img/countries/ch.svg";
import CountryCn from "../../../assets/img/countries/cn.svg";

// Source: https://developers.google.com/public-data/docs/canonical/countries_csv
// Source for continent mapping: https://datahub.io/JohnSnowLabs/country-and-continent-codes-list
// Source for boundaries: https://geojson-maps.ash.ms/ via iot20k/country-geo-json/utils
// Limited to countries with more than 1m inhabitants
import countries from "./data.json";

const svgs = {
  de: CountryDe,
  us: CountryUs,
  se: CountrySe,
  at: CountryAt,
  ch: CountryCh,
  cn: CountryCn
};

export const getSvg = isoCode => {
  return svgs[isoCode.toLowerCase()] || null;
};

export const getCountriesWithSvgs = () => Object.keys(svgs);

export const getCountry = isoCode => countries[isoCode.toLowerCase()];

// TODO: Add missing continents
const continentPositions = {
  Europe: {
    lat: 55.752222,
    lng: 37.615556
  },
  "North America": {
    lat: 40.7141667,
    lng: -74.0063889
  },
  Asia: {
    lat: 31.045556,
    lng: 121.399722
  }
};

export const getPositionForContinent = continent =>
  continentPositions[continent];

export const getPositionForCountry = isoCode => {
  const country = countries[isoCode.toLowerCase()];
  if (!country) {
    return null;
  }

  return {
    lat: country.lat,
    lng: country.lng
  };
};

export const getCountryName = countryCode => {
  const countryCodeLowerCase = countryCode.toLowerCase();
  if (countries.hasOwnProperty(countryCodeLowerCase)) {
    return countries[countryCodeLowerCase].name;
  } else {
    return countryCode;
  }
};
