import React, { useEffect } from "react";

import ReactAnime from "./ReactAnime";
import MessagesTodayRace from "./MessagesTodayRace";
import { Typography } from "@material-ui/core";
import { primaryColor } from "../../assets/jss/material-kit-pro-react";

const { Anime } = ReactAnime;

/*
TODO:
* Automatically call onSceneFinished once the race is finished
*/

// Memoize the component to prevent constant re-render when the parent component re-renders. It does that each time
// that new live data comes in, even when the data update does not affect the component at all.
const Scene4 = React.memo(props => {
  // Every scene *must* call onSceneFinished once it is done
  useEffect(() => {
    const timeout = setTimeout(() => {
      // After all animations have finished
      // TODO: Enable this again!
      //props.onSceneFinished();
    }, 10000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  // TODO: Either use Anime or remove it
  return (
    <Anime
      //onFinished={() => props.onSceneFinished()}
      initial={[]}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
          background: primaryColor
        }}
      >
        {props.data && <MessagesTodayRace data={props.data} />}
      </div>
    </Anime>
  );
});

export default Scene4;
