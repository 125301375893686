import React, { useEffect, useState } from "react";

import slackMarkdownFile from "./SlackMarkdown.md";
import ReactMarkdown from "react-markdown";
import { markdownRenderers } from "./DocUtils";

const slackImages = {};

const SlackDocumentation = props => {
  const [slackText, setSlackText] = useState(null);

  useEffect(() => {
    fetch(slackMarkdownFile)
      .then(response => response.text())
      .then(text => {
        setSlackText(text);
      });
  }, []);

  return (
    <div>
      {slackText && (
        <ReactMarkdown
          source={slackText}
          transformImageUri={image => slackImages[image]}
          renderers={markdownRenderers}
        />
      )}
    </div>
  );
};

export default SlackDocumentation;
