import React, { useEffect } from "react";

import ReactAnime from "./ReactAnime";
import SimpleLine from "./SimpleLine";
import { getCountryName, getSvg } from "./CountryService/CountryService";
import * as portals from "react-reverse-portal";

const { Anime } = ReactAnime;

// Memoize the component to prevent constant re-render when the parent component re-renders. It does that each time
// that new live data comes in, even when the data update does not affect the component at all.
const Scene3 = React.memo(props => {
  let numberOfDevicesText = null;
  let percentOfFleetText = null;
  let newDevicesLast30DaysText = null;

  if (props.country && props.data && props.data.countrySpotlightData) {
    const countryData = props.data.countrySpotlightData[props.country];

    if (countryData) {
      numberOfDevicesText = `${countryData.numberOfDevices} devices`;
      percentOfFleetText = `${countryData.percentOfFleet}% of IoT fleet`;
      newDevicesLast30DaysText = `${countryData.newDevicesLast30Days} new devices in the last 30 days`;
    }
  }

  return (
    <Anime
      onFinished={() => props.onSceneFinished()}
      initial={[
        {
          targets: "#leftPart",
          translateX: 0,
          easing: "easeInOutQuad",
          duration: 1000,
          delay: 1000
        },
        {
          targets: "#leftPart",
          translateX: "-51vw",
          easing: "easeInOutQuad",
          duration: 1000,
          delay: 2000
        },
        {
          targets: "#centerPart",
          translateX: "-50vw",
          easing: "easeInOutQuad",
          duration: 1000,
          timelineOffset: "-=1000"
        },
        {
          targets: "#rightPart",
          translateX: "-50vw",
          easing: "easeInOutQuad",
          duration: 1000
        },
        {
          targets: "#earth",
          opacity: [0, 1],
          easing: "easeInOutQuad",
          duration: 1000,
          timelineOffset: "-=1000"
        },
        {
          targets: ["#centerPart", "#earth"],
          opacity: 0,
          easing: "easeInOutQuad",
          duration: 1000,
          delay: 3000
        }
      ]}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "150vw",
          background: "#14213d"
        }}
      >
        <div
          id="leftPart"
          style={{
            transform: "translateX(-51vw)",
            width: "50vw",
            height: "100%",
            background: "#e5e5e5"
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%"
            }}
          >
            <img
              src={getSvg(props.country)}
              alt={props.country}
              style={{ maxWidth: "80%" }}
            />
          </div>
        </div>

        <div
          id="centerPart"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "50vw",
            height: "100%",
            background: "#14213d",
            overflow: "hidden"
          }}
        >
          <div>
            <SimpleLine
              text="Country Spotlight"
              delay={1000}
              variant="body1"
              lineTranslate={80}
              alternateStyle={true}
            />
            <SimpleLine
              text={getCountryName(props.country)}
              delay={1600}
              variant="body1"
              lineTranslate={80}
              characterDelay={40}
            />
            {numberOfDevicesText && (
              <SimpleLine
                text={numberOfDevicesText}
                delay={2600}
                variant="body1"
                bullet={true}
                lineTranslate={80}
                alternateStyle={true}
              />
            )}
            {newDevicesLast30DaysText && (
              <SimpleLine
                text={newDevicesLast30DaysText}
                delay={3600}
                variant="body1"
                bullet={true}
                lineTranslate={80}
                alternateStyle={true}
              />
            )}
            {percentOfFleetText && (
              <SimpleLine
                text={percentOfFleetText}
                delay={4600}
                variant="body1"
                lineTranslate={80}
                bullet={true}
                alternateStyle={true}
              />
            )}
          </div>
        </div>
        <div
          id="rightPart"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "50vw",
            height: "100%",
            background: "#14213d",
            overflow: "hidden"
          }}
        >
          <div id="earth" style={{ opacity: 0, transform: "translateY(30px)" }}>
            <portals.OutPortal
              node={props.earthContainer}
              data={props.data.liveData}
              country={props.country}
            />
          </div>
        </div>
      </div>
    </Anime>
  );
});

export default Scene3;
