import React, { useState } from "react";

import BarChart from "./BarChartRace/BarChart";
import { getCountryName } from "./CountryService/CountryService";
import { mapKeys, mapValues } from "lodash";
import { Typography } from "@material-ui/core";
import "flagpack/dist/flagpack.css";

const NUMBER_OF_INTERPOLATION_STEPS = 2;

const addLeadingZero = value => value.toString().padStart(2, "0");

const MessagesTodayRace = props => {
  const colors = mapValues(props.data, () => "#fca311");
  const [pointInTime, setPointInTime] = useState("00:00");

  const interpolatedData = {};
  const interpolatedTimeline = [];
  let maxCount = null;

  Object.keys(props.data).forEach((country, countryIndex) => {
    const interpolatedCounts = [];
    const counts = props.data[country];
    for (let i = 0; i < counts.length - 1; i++) {
      const originalCount = counts[i];
      const nextOriginalCount = counts[i + 1];

      for (let j = 0; j < NUMBER_OF_INTERPOLATION_STEPS; j++) {
        const interpolatedCount =
          originalCount +
          ((nextOriginalCount - originalCount) * j) /
            NUMBER_OF_INTERPOLATION_STEPS;
        interpolatedCounts.push(Math.round(interpolatedCount));

        // When processing the first country, we also fill out the timeline...
        // (Yes, a lot is happening in these for loops at the same time)
        if (countryIndex === 0) {
          const minutes = (j * 60) / NUMBER_OF_INTERPOLATION_STEPS;
          interpolatedTimeline.push(
            `${addLeadingZero(i)}:${addLeadingZero(minutes)}`
          );
        }
      }
    }

    // Add the final original value
    const lastCount = counts[counts.length - 1];
    interpolatedCounts.push(lastCount);
    if (countryIndex === 0) {
      interpolatedTimeline.push(`${addLeadingZero(counts.length - 1)}:00`);
    }

    interpolatedData[country] = interpolatedCounts;

    if (!maxCount || lastCount > maxCount) {
      maxCount = lastCount;
    }
  });

  const labels = Object.keys(props.data).reduce((res, item, idx) => {
    // Vertical align and line height is set to counter the text being too low in certain resolutions/aspect ratios
    return {
      ...res,
      ...{
        [item]: (
          <span
            style={{
              fontSize: "2vh",
              color: "#fff",
              lineHeight: "2vh",
              verticalAlign: "top"
            }}
          >
            <span
              className={`fp ${item.toLowerCase()}`}
              style={{ marginRight: "10px" }}
            />
            {getCountryName(item)}
          </span>
        )
      }
    };
  }, {});

  return (
    <div style={{ width: "60vw" }}>
      <Typography variant="h5" style={{ paddingBottom: "5vh", color: "#fff" }}>
        Messages Today Up Until {pointInTime}
      </Typography>
      {/*
            <h3 style={{fontSize: '6vh', 'marginBottom': '10vh'}}>Messages Today</h3>
*/}
      <BarChart
        maxVal={maxCount}
        data={interpolatedData}
        barStyle={{
          height: "2vh",
          marginTop: "1vh",
          borderRadius: "0px"
        }}
        timelineStyle={{
          display: "none"
        }}
        // Vertical align and line height is set to counter the text being too low in certain resolutions/aspect ratios
        textBoxStyle={{
          textAlign: "right",
          color: "rgb(255, 255, 255)",
          fontSize: "3vh",
          lineHeight: "2vh",
          verticalAlign: "top"
        }}
        timeline={interpolatedTimeline}
        colors={colors}
        labels={labels}
        width={[25, 65, 10]}
        len={interpolatedTimeline.length}
        timeout={500}
        delay={500}
        start={true}
        onUpdate={values => setPointInTime(values.timeline)}
      />
    </div>
  );
};

export default MessagesTodayRace;
