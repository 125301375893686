import React, { Component } from "react";
import hologramMap from "../../assets/img/hologram-map.svg";
import hologramSpot from "../../assets/img/hologram-shine.svg";
import { isEqual } from "lodash";
import {
  getPositionForCountry,
  getPositionForContinent
} from "./CountryService/CountryService";

// The time between live updates messages via websocket
const UPDATE_TIME_WINDOW = 1000;

class EarthContainer extends Component {
  sprites = [];
  timers = [];

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  pulse(index, lat, lon) {
    if (!this.miniatureEarth) {
      return;
    }
    this.sprites[index].location = { lat, lng: lon };

    this.sprites[index].animate("scale", 0.3, {
      easing: "out-quad",
      duration: 300,
      complete: function() {
        this.animate("scale", 0.01, {
          easing: "in-quad",
          duration: 300
        });
      }
    });
  }

  componentDidMount() {
    const continent = this.props.continent || "Asia";

    const country = this.props.country;
    const mapStyles = country
      ? `#${country.toUpperCase()} { fill: #fff;}`
      : undefined;

    // Country takes precedence over world region if both are given
    const location = country
      ? getPositionForCountry(country)
      : continent
      ? getPositionForContinent(continent)
      : undefined;

    this.miniatureEarth = new window.Earth(this.el, {
      location,
      zoom: 1.25,
      zoomMax: 1.25,
      quality: 4,
      autoRotate: true,
      autoRotateSpeed: 0.4,
      zoomable: false,
      draggable: false,
      light: "none",
      //texture: hologramMap,
      transparent: true,
      mapLandColor: "rgba(255,255,255,0.1)",
      mapSeaColor: "rgba(15,28,55,0.1)",
      mapBorderColor: "rgba(255,255,255,1.0)",
      mapBorderWidth: 0.4,
      mapStyles
    });

    for (let i = 0; i < 5; i++) {
      this.sprites[i] = this.miniatureEarth.addSprite({
        image: hologramSpot,
        scale: 0.01,
        offset: -0.5
      });
    }

    this.lastSeenPositions = [];
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // TODO: Update the earth accordingly
  }

  componentWillUnmount() {
    console.log("Destroying earth");
    this.timers.forEach(timer => clearTimeout());
    if (this.miniatureEarth) {
      this.miniatureEarth.destroy();
      this.miniatureEarth = undefined;
    }
  }

  render() {
    const width =
      this.props.size && this.props.size === "small" ? "10vw" : "30vw";

    if (this.props.data && !isEqual(this.props.data, this.lastSeenPositions)) {
      // Take top 5 positions
      const numberOfPositionsToShow = 5;
      const positions = this.props.data.positions.slice(
        0,
        numberOfPositionsToShow
      );

      // Distribute the positions to show across the UPDATE_TIME_WINDOW duration
      positions.forEach((position, i) => {
        this.timers[i] = setTimeout(
          () => this.pulse(i, position.lat, position.lon),
          i * (UPDATE_TIME_WINDOW / numberOfPositionsToShow)
        );
      });
      this.lastSeenPositions = this.props.data;
    }

    // The panAndPulse property is used for the first received data
    if (
      this.props.panAndPulse &&
      !isEqual(this.props.panAndPulse, this.lastSeenPanAndPulse)
    ) {
      const position = this.props.panAndPulse.position;
      this.miniatureEarth.goTo(
        { lat: position.lat, lng: position.lon },
        { duration: 2000, easing: "in-out-quad" }
      );
      this.miniatureEarth.autoRotate = false;

      this.timers[0] = setTimeout(
        () => this.pulse(0, position.lat, position.lon),
        2000
      );

      this.lastSeenPanAndPulse = this.props.panAndPulse;
    }

    // If we are not in waiting-for-first-data mode, make sure that auto-rotate is enabled (it may have been disabled)
    if (this.miniatureEarth && !this.props.panAndPulse) {
      this.miniatureEarth.autoRotate = true;
    }

    return (
      <div style={{ width, transition: "all 1s", ...this.props.style }}>
        <div>
          {this.props.replacement ? (
            <svg
              viewBox="0 0 100 100"
              height="100%"
              width="100%"
              style={{ position: "relative" }}
            >
              <circle
                cx="50"
                cy="50"
                r="50"
                stroke="black"
                strokeWidth="0.1"
                fillOpacity={0.0}
              />
            </svg>
          ) : (
            <div
              className="earth-container"
              ref={el => (this.el = el)}
              style={{ width: "100%", height: "100%" }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default EarthContainer;
