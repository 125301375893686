import React, { useEffect, useRef, useState } from "react";
import { divIcon, latLng, latLngBounds, marker } from "leaflet";
import { GeoJSON, Map } from "react-leaflet";
import { getCountry } from "../CountryService/CountryService";
import { makeStyles } from "@material-ui/styles";
import { isEqual } from "lodash";
import "leaflet/dist/leaflet.css";

// The time between live updates messages via websocket
const UPDATE_TIME_WINDOW = 1000;
const DEFAULT_NUMBER_OF_POSITIONS_TO_SHOW = 5;

const getMap = ref => (ref.current ? ref.current.leafletElement : null);

const icon = divIcon({
  className: "non-existent",
  html: '<div class="leaflet-pulse" />',
  iconSize: [22, 22]
});

const useStyles = makeStyles({
  "@global": {
    ".leaflet-container": {
      backgroundColor: "#14213d"
    },
    ".leaflet-pulse": {
      border: "3px solid #999",
      borderRadius: "30px",
      height: "18px",
      width: "18px",
      animation: "pulsate 1s ease-out",
      animationIterationCount: 1,
      backgroundColor: "rgba(0, 0, 0, 0.0)"
    },
    "@keyframes pulsate": {
      "0%": {
        transform: "scale(0.1, 0.1)",
        opacity: 0.0
      },
      "50%": {
        opacity: 1.0
      },
      "100%": {
        transform: "scale(1.2, 1.2)",
        opacity: 0.0
      }
    }
  }
});
const CountryMap = props => {
  const [point1Lat, point1Lon, point2Lat, point2Lon] = getCountry(
    props.countryCode
  ).bounds;

  const bounds = latLngBounds(
    latLng(point1Lat, point1Lon),
    latLng(point2Lat, point2Lon)
  );

  const classes = useStyles();

  // Get access to Leaflet API, idea from https://egghead.io/lessons/react-interface-with-the-react-leaflet-map-api-with-the-useref-and-useeffect-react-hooks
  const mapRef = useRef(null);

  const [mapData, setMapData] = useState(null);
  const [lastSeenPositions, setLastSeenPositions] = useState([]);

  // TODO: Move the loading of the JSON to the country service
  useEffect(() => {
    import(
      `../CountryService/countries/${props.countryCode.toUpperCase()}.json`
    )
      .then(data => setMapData(data))
      .catch(e => console.error("Error loading map data: " + e));
  }, []);

  useEffect(() => {
    if (props.positions && !isEqual(props.positions, lastSeenPositions)) {
      // As the bounds are used the filtering is rather rough. Would have to include the country polygons otherwise.
      const positionsInCountry = props.positions.filter(
        position =>
          position.country.toLowerCase() === props.countryCode.toLowerCase()
      );

      const numberOfPositionsToShow =
        props.numberOfPositionsToShow || DEFAULT_NUMBER_OF_POSITIONS_TO_SHOW;

      const positions = positionsInCountry.slice(0, numberOfPositionsToShow);
      setLastSeenPositions(props.positions);

      // Distribute the positions to show across the UPDATE_TIME_WINDOW duration
      // Note: Currently, the timers are not canceled on unmount. Let's see if this causes any trouble
      positions.forEach((position, i) => {
        setTimeout(
          () => showPosition(position),
          i * (UPDATE_TIME_WINDOW / numberOfPositionsToShow)
        );
      });
    }
  }, [props.positions]);

  const showPosition = position => {
    const map = getMap(mapRef);
    if (map) {
      const createdMarker = marker(position, { icon }).addTo(map);
      setTimeout(() => map.removeLayer(createdMarker), 1000);
    }
  };

  return mapData ? (
    <Map
      style={{ width: "70%", height: "70%", flex: 1 }}
      // Optimization, see https://stackoverflow.com/a/54006851
      preferCanvas={true}
      bounds={bounds}
      maxBounds={bounds}
      zoomControl={false}
      attributionControl={false}
      doubleClickZoom={false}
      dragging={false}
      boxZoom={false}
      touchZoom={false}
      keyboard={false}
      scrollWheelZoon={false}
      tap={false}
      ref={mapRef}
    >
      <GeoJSON
        data={mapData}
        style={() => ({
          color: "#fff",
          interactive: false
        })}
      />
    </Map>
  ) : (
    <div />
  );
};

export default CountryMap;
