import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import VerificationInput from "react-verification-input";
import { DialogTitle, StepContent, StepLabel } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import { Cancel, Check } from "@material-ui/icons";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";

const PairingDialog = props => {
  const [activeStep, setActiveStep] = useState(0);
  const [pairingSuccess, setPairingSuccess] = useState(null);
  const [pairingErrorMessage, setPairingErrorMessage] = useState(null);
  const [checkInProgress, setCheckInProgress] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [screenKey, setScreenKey] = useState(null);
  const [pairingKey, setPairingCode] = useState("");

  const pairScreenHttps = props.functions.httpsCallable("pairScreen");

  const closeDialog = () => {
    setActiveStep(0);
    setPairingSuccess(null);
    setPairingErrorMessage(null);
    setCheckInProgress(false);
    setScreenKey(null);
    setDisplayName("");
    props.onClose();
  };

  const updateDisplayNameAndClose = () => {
    const displayNameToUse =
      displayName && displayName.length > 0 ? displayName : "Unnamed screen";
    props.firestore
      .collection("tenants")
      .doc(props.tenantId)
      .collection("screenKeys")
      .doc(screenKey)
      .update({ displayName: displayNameToUse })
      .then(() => {
        console.log("Successfully set display name to " + displayName);
      })
      .catch(e => console.log("Error setting display name: " + e))
      .finally(() => closeDialog());
  };

  // React to updates in the pairing key input
  useEffect(() => {
    if (pairingKey) {
      if (pairingKey.length === 6) {
        setCheckInProgress(true);
        // TODO: Convert to async/await?
        pairScreenHttps({ pairingKey: pairingKey })
          .then(result => {
            console.log("Added screen via pairing key: " + pairingKey);
            setPairingSuccess(true);
            setPairingErrorMessage(null);
            setCheckInProgress(false);
            setScreenKey(result.data.screenKey);
            setTimeout(() => setActiveStep(1), 1000);
          })
          .catch(e => {
            console.error("Error adding screen key: " + e);
            setPairingSuccess(false);
            setPairingErrorMessage("Unknown pairing key, please check.");
            setCheckInProgress(false);
          });
      } else {
        // Make sure that no error is displayed when not the whole code is entered
        setPairingSuccess(null);
        setPairingErrorMessage(null);
      }
    }
  }, [pairingKey]);

  return (
    <Dialog
      open={props.open}
      onClose={closeDialog}
      fullWidth={true}
      maxWidth="xs"
    >
      {checkInProgress && <LinearProgress />}
      <DialogTitle>Enter pairing code</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step key="pairingKeyEntry">
            <StepLabel>
              {activeStep === 0
                ? "Enter pairing code"
                : "Pairing code has been verified"}
            </StepLabel>
            <StepContent>
              <VerificationInput
                length={6}
                validChars="A-Za-z0-9"
                placeholder=" "
                inputField={{
                  value: pairingKey,
                  onChange: e => setPairingCode(e.target.value.toUpperCase())
                }}
                autoFocus={true}
              />
              {pairingSuccess && (
                <Alert icon={<Check fontSize="inherit" />} severity="success">
                  Pairing code verified
                </Alert>
              )}
              {pairingSuccess === false && (
                <Alert icon={<Cancel fontSize="inherit" />} severity="error">
                  {pairingErrorMessage}
                </Alert>
              )}
            </StepContent>
          </Step>
          <Step key="displayNameEntry">
            <StepLabel>Enter display name</StepLabel>
            <StepContent>
              <TextField
                value={displayName}
                onChange={e => setDisplayName(e.target.value)}
                autoFocus={true}
                placeholder="TV at the entrance"
              />
            </StepContent>
          </Step>
        </Stepper>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button
          variant="outlined"
          color="primary"
          disabled={activeStep !== 1}
          onClick={updateDisplayNameAndClose}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PairingDialog;
