import React, { useEffect, useRef, useState } from "react";
import deepEqual from "deep-equal";

import { makeStyles } from "@material-ui/core/styles";

import "chartist/dist/chartist.min.css";
import Chartist from "chartist";

const useStyles = makeStyles({
  fadeIn: {
    transition: "opacity 1s ease-in",
    opacity: 1.0
  },
  fadedOut: {
    opacity: 0.0
  },
  fadeOut: {
    transition: "opacity 1s ease-in",
    opacity: 0.0
  }
});

// Memoize the component to prevent constant re-render when the parent component re-renders. It does that each time
// that new live data comes in, even when the data update does not affect the component at all.
const MessagePerDayChart = props => {
  const classes = useStyles();

  const options = {
    low: 0,
    height: "60vh"
  };

  const [chart, setChart] = useState(null);

  useEffect(() => {
    const eventEmitter = Chartist.EventEmitter();

    const newChart = new Chartist.Bar(divRef.current, props.data, options);
    newChart.on("draw", data => {
      if (data.type === "bar") {
        data.element.animate(
          {
            y2: {
              begin: 0,
              dur: 1000,
              from: data.y1,
              to: data.y2,
              easing: "easeOutQuart"
            }
          },
          false,
          eventEmitter
        );
      }
    });

    eventEmitter.addEventHandler("animationEnd", param => {
      newChart.off("draw");
    });

    setChart(newChart);

    return () => {
      console.log("Cleaning up chart");
      newChart.detach();
    };
  }, []);

  useEffect(() => {
    if (chart && props.data) {
      chart.update(props.data, options);
    }
  }, [props.data, chart]);

  const divRef = useRef(null);

  const getClass = () => {
    if (props.fadeOut === true) {
      return classes.fadeOut;
    }

    if (props.data) {
      return classes.fadeIn;
    }

    return classes.fadedOut;
  };

  return (
    <div className={getClass()} style={{ width: "50vw" }}>
      <h3 style={{ fontSize: "6vh", marginBottom: "10vh" }}>
        Messages per Day
      </h3>
      <div ref={divRef} />
    </div>
  );
};
export default React.memo(MessagePerDayChart, (prevProps, nextProps) => {
  // Use deep equality instead of shallow one as data object changes, but contents may not
  // TODO: Replace with lodash isEqual and remove deepEqual?
  return deepEqual(prevProps, nextProps);
});
