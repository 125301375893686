import SocketIo from "socket.io-client";

export class BackendChartDataProvider {
  // TODO: Move to helper
  convertToChartistData(docData) {
    const entries = Object.entries(docData).sort(([label1, _], [label2, __]) =>
      label1.localeCompare(label2)
    );
    return {
      labels: entries.map(([label, _]) => label),
      series: [entries.map(([_, value]) => value)]
    };
  }

  constructor(tenantId, firestore, idToken) {
    this.tenantId = tenantId;
    this.firestore = firestore;
    this.idToken = idToken;
    this.newsItems = {};
    this.unsubscribeFunctions = [];
  }

  subscribeToData(newChartDataCallback, newsItemCallback, liveDataCallback) {
    this.unsubscribeFunctions.push(
      this.firestore
        .collection("tenants")
        .doc(this.tenantId)
        .collection("charts")
        .doc("dailyMessageCount")
        .onSnapshot(doc => {
          if (doc.exists) {
            console.log("Fetched chart data: " + doc.data());
            newChartDataCallback(
              "dailyMessageCount",
              this.convertToChartistData(doc.data())
            );
          }
        })
    );

    this.unsubscribeFunctions.push(
      this.firestore
        .collection("tenants")
        .doc(this.tenantId)
        .collection("newsItems")
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach(
            doc =>
              (this.newsItems[doc.id] = {
                name: doc.id,
                ...doc.data()
              })
          );
          newsItemCallback(this.newsItems);
        })
    );

    console.log("Subscribing to live messages");

    const url = "https://live.iot20k.com?token=" + this.idToken;
    //const url = "http://127.0.0.1:8081?token=" + this.idToken;
    this.socket = SocketIo(url);
    this.socket.on("connect", () => console.log("Connected"));
    this.socket.on("disconnect", () => console.log("Disconnected"));
    this.socket.on("data", data => {
      console.log("Received data: " + data);
      const dataObj = JSON.parse(data);
      liveDataCallback({
        numberOfMessagesEver: parseInt(dataObj["tmc"]),
        positions: dataObj["p"],
        messagePerSecond: 0
      });
    });
  }

  close() {
    this.socket.close();
    this.unsubscribeFunctions.forEach(unsubscribeFunction =>
      unsubscribeFunction()
    );
  }
}
