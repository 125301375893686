import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AwsDocumentation from "./AwsDocumentation";
import AzureDocumentation from "./AzureDocumentation";
import HttpDocumentation from "./HttpDocumentation";
import standardPageStyle from "../../assets/jss/standardPageStyle";
import SlackDocumentation from "./SlackDocumentation";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles(standardPageStyle);

const getContent = section => {
  switch (section) {
    case "http":
      return { name: "HTTP", component: <HttpDocumentation /> };
    case "aws":
      return { name: "AWS", component: <AwsDocumentation /> };
    case "azure":
      return { name: "Azure", component: <AzureDocumentation /> };
    case "slack":
      return { name: "Slack", component: <SlackDocumentation /> };
  }
  return { name: "HTTP", component: <HttpDocumentation /> };
};

const DocumentationDetailPage = props => {
  const classes = useStyles();

  const { name, component } = getContent(props.section);

  return (
    <div className={classes.section}>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link to="/documentation" href="/documentation" component={RouterLink}>
          Docs Home
        </Link>
        <Typography>{name}</Typography>
      </Breadcrumbs>
      {component}
    </div>
  );
};

export default DocumentationDetailPage;
