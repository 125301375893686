import React from "react";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import Button from "../../components/CustomButtons/Button";

const PricingCard = props => {
  const classes = props.classes;

  return (
    <Card pricing plain>
      <CardBody pricing plain>
        <h6 className={classes.cardCategory}>{props.name}</h6>
        <h1 className={classes.cardTitle}>
          <small>€</small> {props.pricePerMonth} <small>/mo</small>
        </h1>
        <ul>
          <li>
            <div>
              <b>{props.commEventsPerMonth}</b>
            </div>
            communication events per month
          </li>
          <li>{props.numberOfChartTypes} Chart Types</li>
          <li>{props.typeOfSupport}</li>
        </ul>
      </CardBody>
      <CardFooter pricing className={classes.justifyContentCenter}>
        <Button color="primary" round>
          Get Started
        </Button>
      </CardFooter>
    </Card>
  );
};

export default PricingCard;
