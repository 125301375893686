import React, { useEffect, useState } from "react";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import NavPills from "../../components/NavPills/NavPills";
import Button from "../../components/CustomButtons/Button";
import PricingCard from "./PricingCard";

const SectionPricing = props => {
  const classes = props.classes;

  const [paymentInterval, setPaymentInterval] = useState("yearly");

  const prices = paymentInterval === "yearly" ? [0, 49, 99] : [0, 59, 119];

  return (
    <div className={classes.pricing} id="pricingSection">
      <div className={classes.container}>
        <GridContainer>
          <GridItem
            xs={12}
            sm={6}
            md={6}
            className={
              classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
            }
          >
            <h2 className={classes.title}>Pricing</h2>
            <NavPills
              alignCenter
              color="primary"
              onChange={index =>
                setPaymentInterval(index === 0 ? "yearly" : "monthly")
              }
              tabs={[
                {
                  tabButton: "Yearly",
                  tabContent: ""
                },
                {
                  tabButton: "Monthly",
                  tabContent: ""
                }
              ]}
            />
            <div className={classes.sectionSpace} />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <PricingCard
              name="FREE"
              commEventsPerMonth="1.000.000"
              numberOfChartTypes={4}
              typeOfSupport="Community Support"
              pricePerMonth={prices[0]}
              classes={classes}
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <PricingCard
              name="PLUS"
              commEventsPerMonth="10.000.000"
              numberOfChartTypes={8}
              typeOfSupport="Email Support"
              pricePerMonth={prices[1]}
              classes={classes}
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <PricingCard
              name="ADVANCED"
              commEventsPerMonth="30.000.000"
              numberOfChartTypes={8}
              typeOfSupport="Email Support"
              pricePerMonth={prices[2]}
              classes={classes}
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
};

export default SectionPricing;
