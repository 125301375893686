import React, { useCallback, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { DummyChartDataProvider } from "./DummyChartDataProvider";
import { BackendChartDataProvider } from "./BackendChartDataProvider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import { Fullscreen, Home, MenuOpen } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DeviceOrientation, { Orientation } from "react-screen-orientation";
import DashboardDrawerContent from "./DashboardDrawerContent";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { GlobalHotKeys } from "react-hotkeys";
import { Swipeable } from "react-swipeable";
import { intersection, shuffle, throttle } from "lodash";
import Scene1 from "./Scene1";
import Scene2 from "./Scene2";
import Scene3 from "./Scene3";
import Scene4 from "./Scene4";
import Scene5 from "./Scene5";
import { getCountriesWithSvgs } from "./CountryService/CountryService";
import Scene6 from "./Scene6";
import RotateDevice from "../../components/RotateDevice/RotateDevice";
import Scene7 from "./Scene7";
import SceneSwitcher from "./SceneSwitcher";
import ReactAnime from "./ReactAnime";
import fscreen from "fscreen";
import * as portals from "react-reverse-portal";
import EarthContainer from "./EarthContainer";
import SceneWaitingForFirstData from "./SceneWaitingForFirstData";
import TopDashboardControls from "./TopDashboardControls";

const { Anime } = ReactAnime;

/*
TODO:
 * Re-add news items
*/

// Currently used color palette: https://coolors.co/000000-14213d-fca311-e5e5e5-ffffff
const useStyles = makeStyles({
  snackbar: {},
  snackbarContent: {
    fontSize: "1.0em",
    backgroundColor: "#d2d2d2",
    color: "#000"
  },
  dashboardRoot: {
    backgroundColor: "#14213d",
    color: "#8c8787"
  },
  sceneSwitcherShown: {
    opacity: 1,
    transform: "translateY(0px)"
  },
  sceneSwitcherHidden: {
    opacity: 0,
    transform: "translateY(40px)"
  },
  // See https://stackoverflow.com/questions/28411499/disable-scrolling-on-body
  "@global html": {
    margin: 0,
    height: "100%",
    overflow: "hidden"
  },
  "@global body": {
    margin: 0,
    height: "100%",
    overflow: "hidden",
    backgroundColor: "#14213d"
  },
  "@global .ct-series-a .ct-bar": {
    stroke: "#fca311",
    strokeWidth: "30px"
  },
  "@global .ct-label": {
    color: "#8c8787"
  },
  "@global .ct-grid": {
    stroke: "#8c8787"
  },
  fadeOut: {
    opacity: 0.0,
    transition: "opacity 1s ease-in-out"
  }
});

const keyMap = {
  TOGGLE_AUTO_SWITCH: "shift+a",
  TOGGLE_SHOW_BLADE_BORDERS: "shift+b",
  NEXT_TIMELINE_STEP: ["shift+right", "space"],
  PREV_TIMELINE_STEP: "shift+left",
  TOGGLE_FULLSCREEN: "f"
};

const boundedStep = (value, step, max) => {
  return value + step < 0 ? max - 1 : (value + step) % max;
};

const Dashboard = props => {
  const portalNode = React.useMemo(() => portals.createHtmlPortalNode(), []);

  const [mode, setMode] = useState("backend");
  //const [mode, setMode] = useState("dummy");
  const [autoSwitchEnabled, setAutoSwitchEnabled] = useState(false);
  const [newsItemsEnabled, setNewsItemsEnabled] = useState(true);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const [showControls, setShowControls] = useState(false);
  const [hideControlsTimer, setHideControlsTimer] = useState(null);

  // Chart data state
  const [messagesPerDayData, setMessagesPerDayData] = useState(null);
  const [deviceHeatMapData, setDeviceHeatMapData] = useState(null);
  const [countrySpotlightData, setCountrySpotlightData] = useState(null);
  const [messagesTodayRaceData, setMessagesTodayRaceData] = useState(null);
  const [countriesWithData, setCountriesWithData] = useState([]);

  const [liveData, setLiveData] = useState(null);
  const [newsItems, setNewsItems] = useState({});
  const [currentNewsItemIndex, setCurrentNewsItemIndex] = useState(0);

  const [waitingForFirstData, setWaitingForFirstData] = useState(null);
  const [currentSceneIndex, setCurrentSceneIndex] = useState(3);

  // Every time a new scene is finished and the next is shown, this gets incremented. This is mainly used for
  // repeating, but re-creating the same scene when auto-switch is disabled.
  const [sceneInstanceCounter, setSceneInstanceCounter] = useState(0);

  const fullScreenHandle = useFullScreenHandle();

  // The reason why this is so complicated is that we have to detect when a
  // currentSceneFinished callback is called from the timeline of an already
  // outdated scene. Also, stale state in closures makes it necessary to use
  // the function parameter of the setState methods.
  const currentSceneFinished = sceneNumber => {
    setCurrentSceneIndex(current => {
      if (sceneNumber !== current) {
        return current;
      }

      // If autoSwitch is disabled we show the same scene over and over again (useful for development)
      if (autoSwitchEnabled) {
        return boundedStep(current, 1, scenes.length);
      }

      setSceneInstanceCounter(currentCounter => currentCounter + 1);

      return current;
    });
  };

  const singleNumberTypes = ["numberOfMessagesEver", "numberOfDevices"];

  // Choose a country with data. The list of countries is already shuffled randomly at this point.
  const pickCountry = () =>
    countriesWithData[sceneInstanceCounter % countriesWithData.length] || "de";

  const pickSingleNumberType = () =>
    singleNumberTypes[sceneInstanceCounter % singleNumberTypes.length];

  const scenes = [
    <Scene1
      messagesPerDayData={messagesPerDayData}
      onSceneFinished={() => currentSceneFinished(0)}
      key={`scene-instance-${sceneInstanceCounter}`}
    />,
    <Scene2
      data={liveData}
      earthContainer={portalNode}
      onSceneFinished={() => currentSceneFinished(1)}
      key={`scene-instance-${sceneInstanceCounter}`}
    />,
    <Scene3
      data={{ liveData, countrySpotlightData }}
      earthContainer={portalNode}
      country={pickCountry()}
      onSceneFinished={() => currentSceneFinished(2)}
      key={`scene-instance-${sceneInstanceCounter}`}
    />,
    <Scene4
      data={messagesTodayRaceData}
      onSceneFinished={() => currentSceneFinished(3)}
      key={`scene-instance-${sceneInstanceCounter}`}
    />,
    <Scene5
      data={deviceHeatMapData}
      onSceneFinished={() => currentSceneFinished(4)}
      key={`scene-instance-${sceneInstanceCounter}`}
    />,
    <Scene6
      data={liveData}
      onSceneFinished={() => currentSceneFinished(5)}
      singleNumberType={pickSingleNumberType()}
      key={`scene-instance-${sceneInstanceCounter}`}
    />,
    <Scene7
      data={liveData}
      onSceneFinished={() => currentSceneFinished(6)}
      country={pickCountry()}
      key={`scene-instance-${sceneInstanceCounter}`}
    />
  ];

  const stepScene = step => {
    if (step !== 1 && step !== -1) {
      throw new Error(`Illegal parameter: ${step}`);
    }

    // Functional approach against stale state, see https://dmitripavlutin.com/react-hooks-stale-closures/
    setCurrentSceneIndex(current => boundedStep(current, step, scenes.length));
    setSceneInstanceCounter(current => current + 1);
  };

  // Show the controls on mouse move, but when the mouse has not been moved for 3000ms, hide the controls again.
  const userWasActive = () => {
    setShowControls(true);

    const timer = setTimeout(() => {
      setShowControls(false);
      setHideControlsTimer(null);
    }, 3000);

    setHideControlsTimer(currentHideControlsTimer => {
      if (currentHideControlsTimer) {
        clearTimeout(currentHideControlsTimer);
      }

      return timer;
    });
  };

  useEffect(() => {
    if (newsItemsEnabled) {
      const timeout = setTimeout(() => {
        const numberOfNewsItems = Object.keys(newsItems).length;
        const newNewsItemIndex = (currentNewsItemIndex + 1) % numberOfNewsItems;
        setCurrentNewsItemIndex(newNewsItemIndex);
      }, 10000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [newsItems, newsItemsEnabled, currentNewsItemIndex]);

  useEffect(() => {
    if (mode === "backend" && (!props.tenantDoc || !props.idToken)) {
      return;
    }

    // Even if we are in dummy mode, a tenant doc is required
    if (!props.tenantDoc) {
      return;
    }

    let provider;
    if (mode === "dummy") {
      provider = new DummyChartDataProvider();
    } else if (mode === "backend") {
      // TODO: What about an already existing provider?
      provider = new BackendChartDataProvider(
        props.tenantDoc.id,
        props.firestore,
        props.idToken
      );
    } else {
      throw new Error("Unknown mode: " + mode);
    }

    setWaitingForFirstData(props.tenantDoc.waitingForFirstData);

    console.log("Subscribe to data");

    const handleNewChartData = (chartType, data) => {
      if (chartType === "dailyMessageCount") {
        setMessagesPerDayData(data);
      } else if (chartType === "deviceHeatMap") {
        setDeviceHeatMapData(data);
      } else if (chartType === "countrySpotlight") {
        setCountrySpotlightData(data);
      } else if (chartType === "messagesTodayRace") {
        setMessagesTodayRaceData(data);
      } else if (chartType === "countriesWithData") {
        // Randomly shuffle the countries
        console.log("1 " + getCountriesWithSvgs());
        console.log("2 " + data);
        const countriesWithSvg = intersection(getCountriesWithSvgs(), data);
        setCountriesWithData(shuffle(countriesWithSvg));
      } else {
        console.error("Data update for unknown chart type: " + chartType);
      }
    };

    const handleNewNewsItem = newsItems => {
      console.log(`Received newsItem update`);
      setNewsItems(newsItems);
    };

    const handleNewLiveData = newLiveData => {
      //console.log(`Received live data update: ${JSON.stringify(newLiveData)}`);
      setLiveData(newLiveData);
    };

    provider.subscribeToData(
      handleNewChartData,
      handleNewNewsItem,
      handleNewLiveData
    );

    // Cleanup
    return () => {
      console.log("Cleanup chart provider");
      provider.close();
      setMessagesPerDayData(null);
      setLiveData(null);
    };
  }, [mode, props.tenantDoc, props.idToken]);

  const classes = useStyles({ showControls });

  const currentNewsItem =
    Object.keys(newsItems).length > 0
      ? newsItems[Object.keys(newsItems)[currentNewsItemIndex]]
      : null;

  const hotKeyNextScene = useCallback(() => {
    stepScene(1);
  }, []);

  const hotKeyPrevScene = useCallback(() => {
    stepScene(-1);
  }, []);

  const toggleFullScreen = () => {
    // Must fallback to fscreen directly, because fullScreenHandle.active goes stale in this closure (always false)
    fscreen.fullscreenElement !== null
      ? fullScreenHandle.exit()
      : fullScreenHandle.enter();
  };

  fscreen.onfullscreenchange = () =>
    console.log(fscreen.fullscreenElement !== null);

  const hotKeyHandlers = {
    TOGGLE_AUTO_SWITCH: () => setAutoSwitchEnabled(current => !current),
    NEXT_TIMELINE_STEP: hotKeyNextScene,
    PREV_TIMELINE_STEP: hotKeyPrevScene,
    // Does not work yet
    TOGGLE_FULLSCREEN: toggleFullScreen
  };

  const onMouseMoveThrottled = useCallback(
    throttle(userWasActive, 1000, { leading: true }),
    []
  );

  return (
    <FullScreen handle={fullScreenHandle}>
      <portals.InPortal node={portalNode}>
        <EarthContainer />
      </portals.InPortal>
      <GlobalHotKeys keyMap={keyMap} handlers={hotKeyHandlers} />
      <Swipeable
        onSwipedLeft={eventData => hotKeyNextScene()}
        onSwipedRight={eventData => hotKeyPrevScene()}
      >
        <div
          className={classes.dashboardRoot}
          style={{ cursor: showControls ? "auto" : "none" }}
        >
          {/*
                The banner is disabled for now as it was tricky with CSS. Re-add it later on.
                {!props.user && <Alert severity="info" style={{zIndex: 1}} onClose={() => {
                }}>
                    In order to view your data on the dashboard, either <RouterLink
                    to="/login">login</RouterLink> or
                    <RouterLink to="/pair"> pair this device</RouterLink> as a screen. Otherwise, only dummy mode is
                    available.</Alert>
                }
*/}

          {/* The buttons should hover over the dashboard without impacting the layout*/}
          {props.tenantDoc && (
            <TopDashboardControls
              shown={showControls}
              tenantDoc={props.tenantDoc}
              currentSceneIndex={currentSceneIndex}
              openDrawer={() => setDrawerOpen(true)}
              toggleFullScreen={toggleFullScreen}
            />
          )}

          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
          >
            <DashboardDrawerContent
              mode={mode}
              setMode={setMode}
              autoSwitchEnabled={autoSwitchEnabled}
              setAutoSwitchEnabled={setAutoSwitchEnabled}
              newsItemsEnabled={newsItemsEnabled}
              setNewsItemsEnabled={setNewsItemsEnabled}
            />
          </Drawer>

          <DeviceOrientation lockOrientation={"landscape"}>
            <Orientation orientation="portrait" alwaysRender={false}>
              <div style={{ width: "100%", height: "100vh" }}>
                <RotateDevice>
                  <Typography variant="h5">
                    Please rotate your device
                  </Typography>
                </RotateDevice>
              </div>
            </Orientation>
            <Orientation orientation="landscape" alwaysRender={false}>
              <div
                style={{ width: "100%", height: "100vh" }}
                onMouseMove={onMouseMoveThrottled}
                onClick={useCallback(userWasActive, [])}
              >
                {/* Only show the normal procession of scenes when we have left the waiting-for-first-data mode */}
                {waitingForFirstData ? (
                  <SceneWaitingForFirstData
                    data={liveData}
                    earthContainer={portalNode}
                    tenantDoc={props.tenantDoc}
                    onSceneFinished={() => {
                      setCurrentSceneIndex(0);
                      setWaitingForFirstData(false);
                    }}
                  />
                ) : (
                  scenes[currentSceneIndex]
                )}
              </div>

              {!waitingForFirstData && (
                <SceneSwitcher
                  id="sceneSwitcher"
                  className={
                    showControls
                      ? classes.sceneSwitcherShown
                      : classes.sceneSwitcherHidden
                  }
                  scenes={scenes}
                  style={{
                    transition: "all .3s"
                  }}
                  currentSceneIndex={currentSceneIndex}
                  stepScene={stepScene}
                />
              )}
            </Orientation>
          </DeviceOrientation>
        </div>
      </Swipeable>
    </FullScreen>
  );
};

export default Dashboard;
