import Cities from "./cities";
import { sample, range } from "lodash";

const positionsInGermany = [
  [48.137154, 11.576124],
  [52.520008, 13.404954],
  [50.935173, 6.953101],
  [49.006889, 8.403653],
  [51.514244, 7.468429],
  [49.878708, 8.646927],
  [51.343479, 12.387772],
  [47.5574, 9.707209]
].map(p => ({ lat: p[0], lon: p[1], country: "de" }));

export class DummyChartDataProvider {
  constructor() {
    this.numberOfMessagesEver = 13120;
    this.numberOfDevices = 1201;
  }

  subscribeToData(newChartDataCallback, newsItemCallback, newLiveDataCallback) {
    newChartDataCallback("dailyMessageCount", {
      labels: [
        "2020-06-03",
        "2020-06-04",
        "2020-06-05",
        "2020-06-06",
        "2020-06-07"
      ],
      series: [[100, 200, 300, 400, 1500]]
    });

    // Mapping from heatMapName -> gridId (aka dotId) -> number of devices
    newChartDataCallback("deviceHeatMap", {
      europe1: {
        407: 5,
        307: 10,
        1016: 11,
        1061: 13,
        1214: 8
      }
    });

    newChartDataCallback("countrySpotlight", {
      de: {
        newDevicesLast30Days: 10,
        percentOfFleet: 37,
        numberOfDevices: 137
      },
      us: {
        newDevicesLast30Days: 2,
        percentOfFleet: 3.8,
        numberOfDevices: 14
      },
      ch: {
        newDevicesLast30Days: 7,
        percentOfFleet: 9.2,
        numberOfDevices: 38
      },
      cn: {
        newDevicesLast30Days: 7,
        percentOfFleet: 9.2,
        numberOfDevices: 38
      }
    });

    // First entry in all arrays is 00:00, second is 01:00, etc
    newChartDataCallback("messagesTodayRace", {
      de: [1000, 3000, 3000, 5000, 9000, 11000, 15000, 16000],
      cn: [500, 1500, 1500, 4500, 8500, 9500, 12000, 17000],
      us: [2000, 2500, 4500, 5000, 10000, 12000, 12000, 18000]
    });

    newChartDataCallback("countriesWithData", ["de", "us", "ch", "cn"]);

    newsItemCallback({
      averageDailyIncrease: {
        name: "averageDailyIncrease",
        text: "There was some increase for sure."
      },
      percentIncreaseSinceYesterday: {
        name: "percentIncreaseSinceYesterday",
        text: "The number of messages increased by 20% since yesterday."
      }
    });

    this.interval = setInterval(() => {
      this.numberOfMessagesEver += Math.floor(Math.random() * 50);
      this.numberOfDevices += Math.floor(Math.random() * 2);

      const positions = range(5)
        .map(() => sample(Cities))
        .map(city => ({
          lat: city.Latitude,
          lon: city.Longitude,
          country: city.Country
        }))
        .concat(
          positionsInGermany.map(position => ({
            ...position,
            lat: position.lat + (Math.random() - 0.5),
            lon: position.lon + (Math.random() - 0.5)
          }))
        );

      newLiveDataCallback({
        numberOfMessagesEver: this.numberOfMessagesEver,
        numberOfDevices: this.numberOfDevices,
        messagesPerSecond: Math.floor(Math.random() * 50),
        positions
      });
    }, 1000);
  }

  close() {
    clearInterval(this.interval);
  }
}
