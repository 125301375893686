import React from "react";

import SectionPricing from "./SectionPricing";
import { makeStyles } from "@material-ui/core/styles";
import standardPageStyle from "../../assets/jss/standardPageStyle";

const useStyles = makeStyles(standardPageStyle);

const PricingPage = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.main} style={{ backgroundColor: "#fff" }}>
        <div className={classes.container}>
          <SectionPricing classes={classes} />
        </div>
      </div>
    </>
  );
};

export default PricingPage;
