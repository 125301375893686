import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Favorite from "@material-ui/icons/Favorite";
import Footer from "../components/Footer/Footer";

const Iot20kFooter = props => {
  const classes = props.classes;

  return (
    <Footer
      className={classes.footer}
      content={
        <div>
          <div className={classes.left}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <a href="/" target="_blank" className={classes.block}>
                  About us
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a href="/" target="_blank" className={classes.block}>
                  Imprint
                </a>
              </ListItem>
            </List>
          </div>
          <div className={classes.right} style={{ paddingRight: "1em" }}>
            &copy; {1900 + new Date().getYear()}, All rights reserved
          </div>
        </div>
      }
    />
  );
};

export default Iot20kFooter;
