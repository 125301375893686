import React, { useEffect } from "react";
import ReactAnime from "./ReactAnime";
import MessagePerDayChart from "./MessagePerDayChart";

const { Anime } = ReactAnime;

const Scene1 = props => {
  // Every scene *must* call onSceneFinished once it is done
  useEffect(() => {
    // TODO: Change back to 5000
    const timeout = setTimeout(() => props.onSceneFinished(), 50000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%"
      }}
    >
      <div>
        <Anime
          initial={[
            {
              targets: "#chart-container",
              opacity: 1,
              translateY: 0,
              easing: "easeInOutQuad",
              duration: 2000
            },
            {
              targets: "#chart-container",
              opacity: 0,
              easing: "easeInOutQuad",
              duration: 2000,
              // TODO: Change back to 1000
              delay: 100000
            }
          ]}
        >
          <div id="chart-container" style={{ opacity: 0 }}>
            <MessagePerDayChart data={props.messagesPerDayData} />
          </div>
        </Anime>
      </div>
    </div>
  );
};

export default Scene1;
