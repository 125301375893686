import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import "highlight.js/styles/a11y-dark.css";
import ExampleRequest from "./ExampleRequest";

const ExampleRequestDialog = props => {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Example Request</DialogTitle>
      <DialogContent>
        <ExampleRequest tenantDoc={props.tenantDoc} />
      </DialogContent>
    </Dialog>
  );
};

export default ExampleRequestDialog;
