import React, { useCallback, useState } from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router";
import loginPageStyle from "../../assets/jss/material-kit-pro-react/views/loginPageStyle";
import { DoubleArrow } from "@material-ui/icons";
import LoadingButton from "../../components/LoadingButton";
import { sleep, timezones } from "../../utils";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles(loginPageStyle);

const MAX_NUMBER_OF_TRIES = 5;

const inputValid = (tenantName, timezone) => {
  return timezone && tenantNameValid(tenantName);
};

const tenantNameValid = tenantName =>
  tenantName && tenantName.length > 3 && tenantName.length < 100;

const SignupPage = props => {
  const classes = useStyles();

  const [tenantName, setTenantName] = useState("");
  const [timezone, setTimezone] = useState("");
  const [inProgress, setInProgress] = useState(false);

  const initTenantHttps = props.functions.httpsCallable("initTenant");

  const history = useHistory();

  const initTenant = async function() {
    await initTenantHttps({
      tenantName,
      timezone
    });

    let numberOfTries = 0;
    while (numberOfTries < MAX_NUMBER_OF_TRIES) {
      await props.auth.currentUser.getIdToken(true);

      const nextIdTokenResult = await props.auth.currentUser.getIdTokenResult();

      const type = nextIdTokenResult.claims.typ;
      const tenant = nextIdTokenResult.claims.tnt;

      if (type && tenant) {
        return;
      }

      console.error(
        `Even after refreshing the id token, the 'typ' (value: ${type}) and/or 'tnt' (value: ${tenant}) custom claim are not present. Try: ${numberOfTries}/${MAX_NUMBER_OF_TRIES}`
      );

      numberOfTries++;
      await sleep(1000);
    }
  };

  const signup = useCallback(async () => {
    setInProgress(true);
    await initTenant();
    await props.signupFinished({
      tenantName,
      timezone
    });
    history.push("/dashboard");
  }, [tenantName, timezone]);

  return (
    <div className={classes.container}>
      <GridContainer justify="center" style={{ paddingTop: "10%" }}>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <form className={classes.form}>
              <CardHeader color="primary" signup className={classes.cardHeader}>
                <h4 className={classes.cardTitle}>Welcome to IoT 20k</h4>
              </CardHeader>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.textCenter}>
                      {props.loginState.user && (
                        <>
                          <TextField
                            label="Your Email"
                            value={props.loginState.user.email}
                            className={classes.signupInputField}
                            disabled
                          />
                        </>
                      )}
                      <TextField
                        label="Company or project name *"
                        value={tenantName}
                        autoFocus
                        helperText="Length must be between 3 and 100 characters"
                        className={classes.signupInputField}
                        onChange={e => setTenantName(e.target.value)}
                      />
                      <Autocomplete
                        options={timezones}
                        getOptionLabel={option => option}
                        autoHighlight={true}
                        onChange={(e, newValue) => setTimezone(newValue)}
                        renderInput={params => (
                          <TextField
                            {...params}
                            className={classes.signupInputField}
                            label="Timezone *"
                            style={{ paddingBottom: "30px" }}
                          />
                        )}
                      />

                      <LoadingButton
                        pending={inProgress}
                        pendingPosition="start"
                        startIcon={<DoubleArrow />}
                        variant="outlined"
                        onClick={signup}
                        disabled={!inputValid(tenantName, timezone)}
                      >
                        Complete Sign up
                      </LoadingButton>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default SignupPage;
