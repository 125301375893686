import React, { useCallback, useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import Odometer from "react-odometerjs";
import { throttle } from "lodash";

const BigSingleNumber = props => {
  const [value, setValue] = useState(props.value);

  const numberOfDigits = props.value.toString().length;
  // numberOfDigits: 5 => fontSize: 20
  // numberOfDigits: 1 => fontSize: 70
  // numberOfDigits: 10 => fontSize: 10
  const fontSize = Math.min(100 / numberOfDigits, 40);

  const debouncedSave = useCallback(
    throttle(nextValue => {
      setValue(nextValue);
    }, props.maxUpdateInterval),
    []
  );

  useEffect(() => {
    if (props.value) {
      debouncedSave(props.value);
    }
  }, [props.value]);
  return (
    <>
      {value && (
        <Typography
          className={props.className}
          style={{ fontSize: `${fontSize}vw`, transition: "font-size 500ms" }}
          variant="h1"
        >
          <Odometer format="(,ddd)" duration={1000} value={value} />
        </Typography>
      )}
    </>
  );
};

export default BigSingleNumber;
