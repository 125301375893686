import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import {
  Fullscreen,
  Home,
  KeyboardArrowDown,
  LibraryBooksOutlined,
  MenuOpen,
  Settings
} from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Paper, Popover } from "@material-ui/core";
import ExampleRequest from "../AdministrationPage/ExampleRequest";

const useStyles = makeStyles({
  topControlsShown: {
    opacity: 1,
    transform: "translateY(0px)"
  },
  topControlsHidden: {
    opacity: 0,
    transform: "translateY(-40px)"
  },
  button: {
    marginLeft: "5px",
    marginRight: "5px",
    "&:hover": {
      color: "#fff"
    },
    "&:focus": {
      color: "#fff"
    }
  },
  blob: {
    display: "inline-block",
    background: "#fff",
    borderRadius: "50%",
    boxShadow: "0 0 0 0 rgba(255, 255, 255, 1)",
    margin: "5px",
    height: "10px",
    width: "10px",
    transform: "scale(1)",
    animation: "pulse 2s infinite"
  },
  "@global": {
    "@keyframes pulse": {
      "0%": {
        transform: "scale(0.95)",
        boxShadow: "0 0 0 0 rgba(255, 255, 255, 0.7)"
      },
      "70%": {
        transform: "scale(1)",
        boxShadow: "0 0 0 10px rgba(255, 255, 255, 0)"
      },
      "100%": {
        transform: "scale(0.95)",
        boxShadow: "0 0 0 0 rgba(255, 255, 255, 0)"
      }
    }
  }
});

const ExampleButton = props => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    props.onClick(event);
  };

  const handleClose = () => {
    setAnchorEl(null);
    props.onClose();
  };

  return (
    <>
      <Button
        endIcon={<KeyboardArrowDown />}
        size="small"
        color="inherit"
        variant="outlined"
        onClick={handleClick}
        className={classes.button}
      >
        {props.label}
      </Button>
      <Popover
        open={props.open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Typography
          style={{
            paddingLeft: "13px",
            paddingRight: "13px",
            paddingTop: "13px"
          }}
        >
          This example includes your API-KEY so you can simply <br />
          copy, paste and run it without modification.
        </Typography>
        <Paper square style={{ padding: "13px" }}>
          <ExampleRequest
            language={props.language}
            tenantDoc={props.tenantDoc}
          />
        </Paper>
      </Popover>
    </>
  );
};

const TopDashboardControls = props => {
  const classes = useStyles();

  const [openedExample, setOpenedExample] = useState(null);

  return (
    <div
      className={classes.topControlsShown}
      /*
      TODO: Re-enable hiding!
      className={
        props.shown ? classes.topControlsShown : classes.topControlsHidden
      }
*/
      style={{
        color: "#fff",
        position: "fixed",
        zIndex: "1",
        transition: "all .3s",
        borderBottom: "1px solid white",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        // First child div to the left, second to the right
        justifyContent: "space-between"
      }}
    >
      <div style={{ display: "flex", direction: "row", alignItems: "center" }}>
        <div className={classes.blob} />
        <div
          style={{
            textAlign: "center",
            justifyContent: "center",
            lineHeight: "20px"
          }}
        >
          Awaiting first request
        </div>
        <div>
          <ExampleButton
            label="curl"
            language="bash"
            tenantDoc={props.tenantDoc}
            onClick={() => setOpenedExample("curl")}
            onClose={() => setOpenedExample(null)}
            open={openedExample === "curl"}
          />
        </div>
        <div>
          <ExampleButton
            label="Powershell"
            language="powershell"
            tenantDoc={props.tenantDoc}
            onClick={() => setOpenedExample("powershell")}
            onClose={() => setOpenedExample(null)}
            open={openedExample === "powershell"}
          />
        </div>
        <div>
          <Button
            component={RouterLink}
            to="/documentation"
            target="_blank"
            startIcon={<LibraryBooksOutlined />}
            size="small"
            color="inherit"
            variant="outlined"
            className={classes.button}
          >
            Docs
          </Button>
        </div>
        <div>
          <Typography component="span" variant="body1" style={{ opacity: 0.1 }}>
            {props.currentSceneIndex + 1}
          </Typography>
        </div>
      </div>
      <div style={{ display: "flex", direction: "row", alignItems: "center" }}>
        <IconButton onClick={props.openDrawer} style={{ color: "white" }}>
          <MenuOpen />
        </IconButton>
        <IconButton component={RouterLink} to="/" style={{ color: "white" }}>
          <Home />
        </IconButton>
        <IconButton
          component={RouterLink}
          to="/administration"
          style={{ color: "white" }}
        >
          <Settings />
        </IconButton>
        <IconButton onClick={props.toggleFullScreen} style={{ color: "white" }}>
          <Fullscreen />
        </IconButton>
      </div>
    </div>
  );
};

export default TopDashboardControls;
