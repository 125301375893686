import React from "react";
import CurrentValue from "./CurrentValue";
import AlternativeCurrentValue from "./AlternativeCurrentValue";

const CurrentValues = props => {
  return (
    <>
      <AlternativeCurrentValue
        delay={1000}
        value="202,391"
        label="Number of devices"
        retract={props.retract}
      />

      <AlternativeCurrentValue
        delay={1200}
        value="223,202"
        label="Transferred bytes today"
        retract={props.retract}
      />

      <AlternativeCurrentValue
        delay={1400}
        value={props.data ? props.data.numberOfMessagesEver : "Unknown"}
        label="Number of messages ever"
        retract={props.retract}
      />
    </>
  );
};

export default CurrentValues;
