import React, { useCallback, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import Odometer from "react-odometerjs";
import { throttle } from "lodash";

const CurrentValue = props => {
  const [value, setValue] = useState(props.value);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [textOpacity, setTextOpacity] = useState(1);

  const maxUpdateInterval = props.maxUpdateInterval || 2000;

  const debouncedSave = useCallback(
    throttle(
      nextValue => {
        setValue(nextValue);
      },
      maxUpdateInterval,
      { trailing: true }
    ),
    []
  );

  useEffect(() => {
    if (props.value) {
      debouncedSave(props.value);
    }
  }, [props.value]);

  useEffect(() => {
    if (props.retract) {
      setWidth(0);
      setTextOpacity(0);
    }
  }, [props.retract]);

  useEffect(() => {
    if (!props.retract) {
      const timeout = setTimeout(() => {
        setHeight(50);
        setWidth(1.0);
      }, props.delay);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [props.delay, props.retract]);

  return (
    <Box
      display="flex"
      flexDirection="column-reverse"
      justifyContent="flex-start"
      alignItems="flex-end"
      height="25vh"
    >
      <Box
        style={{
          background: "#000",
          transition: "width .3s, padding .3s, transform .3s",
          overflow: "hidden",
          transform: `scaleX(${width})`,
          transformOrigin: "right",
          padding: "3px",
          width: "15vw",
          color: "#fff",
          fontSize: "x-large"
        }}
      >
        <span
          style={{
            opacity: textOpacity,
            transition: "opacity .3s"
          }}
        >
          <Odometer format="(,ddd)" duration={1000} value={value} />
        </span>
      </Box>
      <Box
        style={{
          background: "#fca311",
          transition: "max-height .5s ease-in-out .5s, transform .3s",
          overflow: "hidden",
          padding: width === 0 ? "0px" : "3px",
          maxHeight: `${height}px`,
          transform: `scaleX(${width})`,
          transformOrigin: "right",
          width: "15vw",
          color: "#000"
        }}
      >
        <Typography
          style={{
            fontSize: "1.5vh",
            opacity: textOpacity,
            transition: "opacity .3s"
          }}
          noWrap={true}
        >
          {props.label}
        </Typography>
      </Box>
    </Box>
  );
};

export default CurrentValue;
