import React from "react";
import navSvg from "assets/img/navCircle.svg";
import navSvgActive from "assets/img/navCircleActive.svg";

const CircleNavBar = props => {
  return (
    <span style={{ paddingLeft: "5px", display: "inline-block" }}>
      <span>
        {props.scenes.map((item, key) => (
          <a key={key} href="#" style={{ marginLeft: "-5px" }}>
            <img
              src={key === props.currentSceneIndex ? navSvgActive : navSvg}
              alt=""
            />
          </a>
        ))}
      </span>
    </span>
  );
};

export default CircleNavBar;
