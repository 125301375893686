import React, { useState } from "react";
import Highlight from "react-highlight";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "@material-ui/core/Button";
import { Assignment, Room } from "@material-ui/icons";

const getRequestContent = (language, apiKey, deviceId, lat, lon) => {
  if (language === "bash") {
    return `curl -X POST https://data.iot20k.com \\\n\t-H API-KEY:${apiKey} \\\n\t-d '{"id": "${deviceId}", "lat": ${lat}, "lon": ${lon}}'`;
  }

  if (language === "powershell") {
    const nl = "`\n\t";
    return `Invoke-RestMethod -Method POST ${nl}-Headers @{'API-KEY'='${apiKey}'} \`\n\t-ContentType 'application/json' -uri 'https://data.iot20k.com' \`\n\t-Body (@{id='${deviceId}'; lat=${lat}; lon=${lon} }|ConvertTo-Json)`;
  }

  return null;
};

const ExampleRequest = props => {
  const [position, setPosition] = useState({ lat: 50.2, lon: 10.1 });

  const useUserPosition = () => {
    navigator.geolocation.getCurrentPosition(pos =>
      setPosition({
        lat: pos.coords.latitude.toFixed(2),
        lon: pos.coords.longitude.toFixed(2)
      })
    );
  };

  const language = props.language || "bash";

  const requestContent = getRequestContent(
    language,
    props.tenantDoc.apiKey,
    "somedevice",
    position.lat,
    position.lon
  );

  return (
    <>
      <Highlight
        language={language}
        className={language}
        style={{ fontSize: "xx-small" }}
      >
        {requestContent}
      </Highlight>
      <Button startIcon={<Room />} onClick={useUserPosition}>
        Use your current position
      </Button>
      <CopyToClipboard
        text={requestContent}
        onCopy={() => console.log("Copied!")}
      >
        <Button startIcon={<Assignment />}>Copy request to clipboard</Button>
      </CopyToClipboard>
    </>
  );
};

export default ExampleRequest;
