import React, { useEffect, useState } from "react";
import { Breadcrumbs, Card, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import { Delete, DesktopWindows, NavigateNext } from "@material-ui/icons";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { Link as RouterLink } from "react-router-dom";
import PairingDialog from "./PairingDialog";
import Link from "@material-ui/core/Link";

const ScreenAdministration = props => {
  const [pairingDialogOpen, setPairingDialogOpen] = useState(false);
  const [screens, setScreens] = useState([]);

  const removeScreenHttps = props.functions.httpsCallable("removeScreen");

  const removeScreen = screenKey => {
    removeScreenHttps({ screenKey })
      .then(result => {
        console.log("Removed screen key from tenant: " + screenKey);
      })
      .catch(e => {
        console.error("Error removing screen key from tenant: " + e);
      });
  };

  useEffect(() => {
    if (!props.tenantDoc) {
      return;
    }

    props.firestore
      .collection("tenants")
      .doc(props.tenantDoc.id)
      .collection("screenKeys")
      .get()
      .then(querySnapshot => {
        const screens = querySnapshot.docs.map(doc => ({
          screenKey: doc.id,
          ...doc.data()
        }));
        setScreens(screens);
      });
  }, [props.tenantDoc]);

  return (
    <div>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link
          to="/administration"
          href="/administration"
          component={RouterLink}
        >
          Administration
        </Link>
        <Typography>Screens</Typography>
      </Breadcrumbs>
      <h3>Screens</h3>
      <Typography>
        Screens are used to display the dashboard, e.g. the TV screen in the
        entrance of your office. On your screen device, open a browser and go to{" "}
        <b>https://iot20k.com/pair</b>. You will be shown a pairing code. Enter
        this code here to pair the screen to your account.
      </Typography>
      <Grid container>
        {screens &&
          screens.map((screen, index) => {
            return (
              <Grid item key={screen.screenKey} md={4}>
                <Card style={{ maxWidth: "300px" }}>
                  <CardHeader title={screen.displayName} />
                  <CardContent>
                    <Typography
                      component="div"
                      style={{ color: "#333", fontSize: "9px" }}
                    >
                      {screen.screenKey}
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <DesktopWindows style={{ fontSize: 80 }} />
                    </Box>
                  </CardContent>
                  <CardActions>
                    <IconButton onClick={() => removeScreen(screen.screenKey)}>
                      <Delete />
                    </IconButton>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
      </Grid>
      <Button variant="outlined" onClick={() => setPairingDialogOpen(true)}>
        Enter pairing code to add screen
      </Button>
      {props.tenantDoc && (
        <PairingDialog
          open={pairingDialogOpen}
          onClose={() => setPairingDialogOpen(false)}
          functions={props.functions}
          firestore={props.firestore}
          tenantId={props.tenantDoc.id}
        />
      )}
    </div>
  );
};

export default ScreenAdministration;
