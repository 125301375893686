import React, { useEffect, useState } from "react";

import awsMarkdownFile from "./AwsMarkdown.md";
import awsImage1 from "../../assets/img/aws/aws-1.png";
import awsImage2 from "../../assets/img/aws/aws-2.png";
import awsImage3 from "../../assets/img/aws/aws-3.png";
import awsImage4 from "../../assets/img/aws/aws-4.png";
import awsImage5 from "../../assets/img/aws/aws-5.png";
import awsImage6 from "../../assets/img/aws/aws-6.png";
import awsImage7 from "../../assets/img/aws/aws-7.png";
import awsDiagram from "../../assets/img/aws/diagram.png";
import ReactMarkdownWithHtml from "react-markdown/with-html";
import ReactMarkdown from "react-markdown";
import { markdownRenderers } from "./DocUtils";

const awsImages = {
  1: awsImage1,
  2: awsImage2,
  3: awsImage3,
  4: awsImage4,
  5: awsImage5,
  6: awsImage6,
  7: awsImage7,
  diagram: awsDiagram
};

const AwsDocumentation = props => {
  const [awsText, setAwsText] = useState(null);

  useEffect(() => {
    fetch(awsMarkdownFile)
      .then(response => response.text())
      .then(text => {
        setAwsText(text);
      });
  }, []);

  return (
    <div>
      {awsText && (
        <ReactMarkdown
          source={awsText}
          transformImageUri={image => awsImages[image]}
          renderers={markdownRenderers}
        />
      )}
    </div>
  );
};

export default AwsDocumentation;
