import React, { useState } from "react";
import {
  Breadcrumbs,
  Chip,
  LinearProgress,
  TableCell,
  TableContainer,
  Tooltip,
  Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import standardPageStyle from "../../assets/jss/standardPageStyle";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import ExampleRequestDialog from "./ExampleRequestDialog";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import { NavigateNext, Visibility, VisibilityOff } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import slackLogo from "assets/img/slack/logo.svg";

const useStyles = makeStyles(standardPageStyle);

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const AdministrationPage = props => {
  const classes = useStyles();

  const [showApiKey, setShowApiKey] = useState(false);
  const [exampleRequestDialogOpen, setExampleRequestDialogOpen] = useState(
    false
  );

  return (
    <div className={classes.main} style={{ paddingBottom: "200px" }}>
      <div className={classes.container}>
        <div style={{ color: "#000" }}>
          {props.tenantDoc && (
            <span>
              <Breadcrumbs
                separator={<NavigateNext fontSize="huge" />}
                aria-label="breadcrumb"
              >
                <Typography>Administration</Typography>
              </Breadcrumbs>
              <h3>General information</h3>
              <TableContainer component={Paper}>
                <Table className={classes.table}>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ width: "30%" }}
                      >
                        <Typography className={classes.adminSetting}>
                          Tenant Name
                        </Typography>
                      </TableCell>
                      <TableCell style={{ width: "100%" }}>
                        <Typography className={classes.adminSettingValue}>
                          {props.tenantDoc && props.tenantDoc.tenantName}
                        </Typography>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.adminSetting}>
                          Tenant ID
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.adminSettingValue}>
                          {props.tenantDoc && props.tenantDoc.id}
                        </Typography>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.adminSetting}>
                          Number of requests this month
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <LinearProgressWithLabel value={10} />
                      </TableCell>
                      <TableCell />
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.adminSetting}>
                          API Key
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <div>
                          <Tooltip
                            title={showApiKey ? "Hide API Key" : "Show API Key"}
                          >
                            <IconButton
                              onClick={() => setShowApiKey(value => !value)}
                            >
                              {showApiKey ? (
                                <VisibilityOff fontSize="small" />
                              ) : (
                                <Visibility fontSize="small" />
                              )}
                            </IconButton>
                          </Tooltip>
                          <Typography
                            component="span"
                            className={classes.adminSettingValue}
                          >
                            {showApiKey
                              ? props.tenantDoc.apiKey
                              : "••••••••••••••••••••••••"}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <h3>Example Request</h3>
              <Button onClick={() => setExampleRequestDialogOpen(true)}>
                Show example request
              </Button>
              <h3>Integrations</h3>

              <TableContainer component={Paper}>
                <Table className={classes.table}>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Chip label="Disabled" size="small" />
                      </TableCell>
                      <TableCell
                        style={{ width: "100%", whiteSpace: "nowrap" }}
                      >
                        <Typography style={{ fontWeight: "bold" }}>
                          <img
                            src={slackLogo}
                            alt="Slack"
                            style={{ height: "18px", marginRight: "10px" }}
                          />
                          Slack
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          component={RouterLink}
                          to="/administration/slack"
                        >
                          Configure
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <h3>Screens</h3>
              <Link
                to="/administration/screens"
                href="/administration/screens"
                component={RouterLink}
              >
                Manage Screens
              </Link>
              <ExampleRequestDialog
                open={exampleRequestDialogOpen}
                tenantDoc={props.tenantDoc}
                onClose={() => setExampleRequestDialogOpen(false)}
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdministrationPage;
