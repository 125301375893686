import React, { useEffect, useState } from "react";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";

const SlackAdministration = props => {
  const functions = props.functions;

  const sendNewsItemHttps = functions.httpsCallable("sendNewsItem");

  const [tenantSettings, setTenantSettings] = useState(null);

  const updateTenantSettings = () => {
    props.firestore
      .collection("tenants")
      .doc(props.tenantDoc.id)
      .collection("tenantSettings")
      .doc("singleton")
      .set(tenantSettings)
      .catch(e => console.log("Error saving tenant settings: " + e));
  };

  useEffect(() => {
    if (!props.tenantDoc) {
      return;
    }

    props.firestore
      .collection("tenants")
      .doc(props.tenantDoc.id)
      .collection("tenantSettings")
      .doc("singleton")
      .get()
      .then(docSnapshot => {
        if (docSnapshot.exists) {
          setTenantSettings(docSnapshot.data());
        } else {
          setTenantSettings({ slackUrl: "" });
        }
      })
      .catch(e => console.log("Error loading settings: " + e));
  }, [props.tenantDoc]);

  const sendNewsItemNow = () => {
    sendNewsItemHttps();
  };

  const slackUrlValid =
    tenantSettings === null ||
    tenantSettings.slackUrl === "" ||
    tenantSettings.slackUrl.startsWith("https://");

  return (
    <div>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link
          to="/administration"
          href="/administration"
          component={RouterLink}
        >
          Administration
        </Link>
        <Typography>Slack</Typography>
      </Breadcrumbs>
      <Typography style={{ paddingBottom: "20px", paddingTop: "40px" }}>
        Post news about your IoT fleet to your Slack channel. Instructions can
        be found in the <RouterLink to="/documentation/slack">docs</RouterLink>.
      </Typography>
      {tenantSettings && (
        <>
          <div>
            <Box display="flex" alignItems="center" mb={2}>
              <TextField
                variant="outlined"
                error={!slackUrlValid}
                label="Webhook URL"
                helperText={
                  slackUrlValid
                    ? ""
                    : "Must be a valid URL, starting with https://"
                }
                value={tenantSettings.slackUrl}
                onChange={e =>
                  setTenantSettings({
                    ...tenantSettings,
                    slackUrl: e.target.value
                  })
                }
              />
              <Button onClick={sendNewsItemNow} style={{ marginLeft: "10px" }}>
                Send test news message now
              </Button>
            </Box>
          </div>
          <Button variant="outlined" onClick={updateTenantSettings}>
            Save
          </Button>
        </>
      )}
    </div>
  );
};

export default SlackAdministration;
